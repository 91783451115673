import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Loader from '../App/components/Loader';
import MUIButton from '../App/components/MUIButton';
import MainWithData from '../App/components/MainWithData';
import MainWithoutData from '../App/components/MainWithoutData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import InputKeyboardDatePicker from '../App/components/InputKeyboardDatePicker';
import { filterHistory, setHistory } from './historyActions';
import HistoryTable from './components/HistoryTable';
import './styles.css';

export default function History() {
    const { history, affectedRows } = useSelector((state) => state.history);
    const dispatch = useDispatch();
    const [initialDate, setInitialDate] = useState(() => {
        const today = new Date();
        const makeDate = new Date(today.setMonth(today.getMonth() - 1));
        return makeDate;
    });
    const [finalDate, setFinalDate] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        if (affectedRows === 0)
            dispatch(setHistory({ offset: 0, limit: 10 }, () => { }));

        setLoading(false)
    }, [dispatch, affectedRows])

    function handleFilterHistory() {
        setLoading(true);
        dispatch(filterHistory({ initialDate, finalDate }, (resp) => {
            setLoading(false);

            if (typeof resp === 'string') {
                return setModal({
                    open: true,
                    title: 'Aviso!',
                    text: resp
                })
            }

            if (typeof resp === 'boolean' && !resp) {
                return setModal({
                    open: true,
                    title: 'ERRO!',
                    text: 'Erro no envio das datas para filtrar.'
                })
            }
        }));
    }

    function getTableData() {
        if (history.length === 0)
            return (
                <MainWithoutData title="Lista dos históricos" />
            )

        return (
            <MainWithData title="Lista dos históricos">
                <HistoryTable resultSet={history} />
            </MainWithData>
        )
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card id="header-container">
                    <div className="title-content">
                        <Typography variant="h4">Históricos</Typography>
                        <Typography variant="h6">Busca dos estacionamentos utilizados</Typography>
                    </div>
                    <div className="history-filter-content">
                        <InputKeyboardDatePicker
                            label="Data inicial"
                            value={initialDate}
                            onChange={(date) => setInitialDate(date)}
                            className="filter-itens"
                        />
                        <InputKeyboardDatePicker
                            label="Data final"
                            value={finalDate}
                            onChange={(date) => setFinalDate(date)}
                            className="filter-itens"
                        />
                        <MUIButton
                            color="primary"
                            className="filter-itens"
                            onClick={handleFilterHistory}
                        >
                            Filtrar
                        </MUIButton>
                    </div>
                </Card>
            </Grid>
            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Grid>
    );
}
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Loader from '../App/components/Loader';
import MUIButton from '../App/components/MUIButton';
import AlertModalDialog from '../App/components/AlertModalDialog';
import InputKeyboardDatePicker from '../App/components/InputKeyboardDatePicker';
import './styles.css';

export default function Policies() {

    return (
        <Grid container >
            <Grid item xs={12} id="header"

            >
                <Card id="header-container">
                    
                    <div className="policies-title">
                            Política de Privacidade
                    </div>

                    <div className="policies-content">

                        <span>
                            <strong>1.</strong>  Esta Política de Privacidade se refere aos dados pessoais que o(a) Estacione Fácil poderá obter quando seus 
                            Usuários utilizam dos serviços prestados durante a navegação dentro de nosso ambiente virtual. 
                            Nesta Política de Privacidade, será descrita de que forma serão coletados e armazenados dados a respeito dos Usuários, assim como
                            a forma pela qual tais dados poderão ser utilizados e armazenados nos termos da Lei 12.965/2014 (“Marco Civil da Internet”), 
                            do Decreto 8.771/2016 e da Lei n.º 13.709/2018 (Lei Geral de Proteção de Dados). 
                        </span>
                    </div>

                    <div className="policies-content">

                        Ao aceitar a presente Política de Privacidade é declarado que todo seu conteúdo foi devidamente lido e aceito, 
                        tendo assim todas as cláusulas devidamente validadas pelo Usuário para que atuem em pleno vigor.

                    </div>

                    <div className="policies-content">

                        Ademais, deixa claro que esta Política de Privacidade poderá ser atualizada a qualquer tempo, a exclusivo critério 
                        dos administradores do ambiente virtual aqui discutido. Porém, deixa claro que toda e qualquer comunicação será 
                        devidamente comunicada aos Usuários para nova aceitação de modo de que, caso continuem em utilizar os 
                        serviços providenciados, estarão automaticamente concordando com as alterações realizadas no corpo desta política.

                    </div>


                    <span className="policies-content2"> Obtenção, Armazenamento e Cuidado dos dados pessoais coletados. </span>

                    
                    <div className="policies-content">
                         <span>
                            <strong>2.</strong>  Aceitando nossa Política de Privacidade, fica concedido, por livre e espontânea vontade, permissão para a coleta 
                            e armazenamento dos dados pessoais dos Usuários, que serão tratados da forma abaixo descrita:

                            <ul>
                                <li>Poderão ser coletados informações fornecidas no cadastro ao longo do uso dos Serviços e durante a sua navegação em 
                                nossa plataforma. </li>
                                
                                <li>Eventuais dados de navegação serão utilizados para possibilitar o seu acesso e saber como entrar em contato com o 
                                Usuário quando for necessário.</li>
                                
                                <li>Os Dados pessoais poderão ser utilizados de forma anônima para fins estatísticos e de controle e melhora dos nossos serviços.</li>
                                
                                <li>Serão estabelecidas medidas de segurança razoáveis para proteger todos os dados pessoais providenciados. Entretanto, 
                                lembramos que não existe uma medida de segurança 100% eficaz.</li>
                                
                                <li>Eventualmente, todos os dados coletados pelo Usuário durante o uso dos serviços prestados, poderão ser excluídos ao momento 
                                em que o Usuário desejar, dito isso, somos obrigados a manter os registros de conexão durante o prazo legal.</li>
                                
                                <li>Esta Política de Privacidade se aplica a todos os sites/Apps detidos pelo(a) Estacione Fácil ou qualquer outra página, 
                                mídia social, ferramenta, software ou conteúdo de sua propriedade.</li>
                            </ul>
                        </span>
                        
                        <span>
                            <strong>a.</strong> Nome, CPF, Endereço, E-mail, Telefone.
                        </span>
                    </div>

                    <div className="policies-content">
                        <span>
                            <strong>3.</strong>  O fato de usar o site/app e/ou serviço disponibilizado confirma o consentimento inequívoco e incondicional do 
                            Usuário com esta Política, incluindo os termos de processamento de seus dados pessoais. Na ausência de 
                            consentimento do usuário com esta política e os termos de processamento de seus dados pessoais, o Usuário deve 
                            parar de usar o site/app e/ou o serviço providênciados, reservando à administração o direito de impedir o acesso do 
                            referido Usuário.
                        </span>
                    </div>

                    <div className="policies-content">
                        <span>
                            <strong>4.</strong>  Este ambiente é destinado a usuários com 18 (dezoito) anos de idade ou mais. <strong>Se você tem menos de 
                            18 (dezoito) anos, não poderá usar ou registrar-se para usar este site/app ou seus serviços sem a permissão 
                            ou consentimento dos pais</strong>. Ao concordar com esta Política de Privacidade, você tem a capacidade legal 
                            necessária para cumprir e ficar vinculado por esta Política de Privacidade.
                        </span>
                    </div>

                    <span className="policies-content2">Armazenamento de Informações </span>


                    <div className="policies-content">
                        <span>
                            <strong>5.</strong>  É reconhecida a natureza sensível e confidencial dos dados e demais informações obtidas e armazenadas. Assim 
                            fica estabelecido o compromisso de manter estas informações de natureza confidencial em sigilo, sem utilizá-las ou 
                            divulgá-las sem a autorização do Usuário, exceto nos termos previstos nos Termos de Uso e na Política de 
                            Privacidade, bem como nos limites necessários para a execução das obrigações contratuais e legais, assim como 
                            para a defesa dos interesses do(a) Estacione Fácil e dos Usuários.
                        </span>
                    </div>


                    <span className="policies-content2"> Cuidado com as informações e uso de Cookies </span>
                    
                    <div className="policies-content">                    
                        <span>
                            <strong>6.</strong>  O Usuário concorda que o(a) Estacione Fácil poderá coletar, registrar, organizar, acumular, armazenar, 
                            atualizar, extrair, usar, transferir, remover e destruir dados pessoais e outras informações.
                        </span>
                    </div>

                    <div className="policies-content">
                        <span>
                            <strong>7.</strong> Os atos descritos acima poderão ser processados de forma manual e/ou com o uso de automação. O presente 
                            consentimento é válido até a sua retirada das configurações do Usuário e/ou até que seja solicitado pelo Usuário 
                            de forma direta. A solicitação pode ser enviada por escrito para o endereço eletrônico: 
                            consforsistemas2@gmail.com.
                        </span>
                    </div>

                    <div className="policies-content">
                        <span>
                            <strong>8.</strong>  Dentro dos limites da legislação aplicável, o(a) Estacione Fácil tem o direito de transferir as informações
                            fornecidas pelo Usuário para terceiros.
                        </span>
                    </div>

                    <div className="policies-content">
                        <span>
                            <strong>9.</strong> No tratamento de dados pessoais, serão tomadas as medidas legais, técnicas e organizacionais necessárias para 
                            proteger os dados pessoais contra o acesso não autorizado ou acidental, destruição, modificação, bloqueio, cópia, 
                            disposição, distribuição de dados pessoais, bem como outras ações ilegais em relação a dados pessoais em 
                            cumprimento dos requisitos da legislação brasileira e/ou qualquer outra que venha a ser aplicável.
                        </span>
                    </div>


                    <div className="policies-content">
                        <span>
                            <strong>10.</strong>  Estacione Fácil  faz uso de cookies. Ao acessar nosso site, você concorda em usar cookies nos termos da 
                            nossa Política de Cookies.
                        </span>
                    </div>

                    <span className="policies-content2"> Manutenção dos dados pelo Usuário </span>

                    <div className="policies-content">
                        <span>
                            <strong>11.</strong>  O usuário tem o direito de requerer a exclusão de seus dados pessoais coletados durante o uso do ambiente eletrônico 
                            disponibilizado a qualquer momento, utilizando-se serviço relevante disponibilizado pelo próprio ambiente, ou por meio de 
                            contato direto com a administração por meio do endereço eletrônico disponibilizado acima. Estes direitos podem ser 
                            restringidos da maneira prescrita pela legislação brasileira.
                        </span>
                    </div>

                    <div className="policies-content">
                        <span>
                            <strong>12.</strong>  Caso o Usuário tenha ciência de que seus dados pessoais tenham sido comprometidos, de forma que poderá 
                            afetar seu acesso ao ambiente eletrônico providênciado, ele deverá comunicar imediatamente a administração 
                            para que sejam tomadas todas a medidas de segurança necessárias.
                        </span>
                    </div>


                    <div className="policies-content">
                        <span>
                            <strong>13.</strong>  O Usuário é o único responsável por suas ações relacionadas ao uso do site/app e/ou serviços disponibilizados, 
                            significando que, se tais ações resultarem em violação dos direitos e interesses legítimos de terceiros, bem como 
                            descumpre com a legislação do Brasil, o Usuário concorda em indenizar os prejuízos causados ao(à) 
                            Estacione Fácil  e/ou terceiros como resultado da não execução ou má execução das obrigações do Usuário sob 
                            esta Política e/ou a legislação.
                        </span>
                    </div>

                    <span className="policies-content2"> Responsabilidades e Competências </span>


                    <div className="policies-content">
                        <span>
                            <strong>14.</strong>  O Usuário usa os Serviços por sua conta e risco, sendo o único responsável por perdas incorridas como 
                            resultado do mal-uso pelo do ambiente e/ou dos serviços da Empresa. 
                        </span>
                    </div>

                    <div className="policies-content">
                        <span>
                            <strong>15.</strong>  O(A) Estacione Fácil coopera com as autoridades competentes e com terceiros para garantir o cumprimento 
                            das leis, salvaguardar a integridade e a segurança da Plataforma e de seus usuários, e impedir condutas que 
                            prejudiquem a integridade moral e a honra das pessoas físicas ou jurídicas envolvidas.
                        </span>
                    </div>


                    <div className="policies-content">
                        <span>
                            <strong>16.</strong>  As disposições desta Política são regidas pelas leis do Brasil. Se, por uma razão ou outra, uma ou mais 
                            disposições desta Política forem consideradas inválidas, isso não afeta as demais disposições.
                        </span>
                    </div>


                    <div className="policies-content">
                        <span>
                            <strong>17.</strong>  Para todas as questões o Usuário pode enviar um pedido para o endereço da empresa para a seguinte conta:
                            consforsistemas2@gmail.com.br.
                        </span>
                    </div>
                </Card>
            </Grid>
        </Grid>
    );
}
import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import TextInput from '../../App/components/TextInput';
import Loader from "../../App/components/Loader";
import './styles.css';

export default function ExistAddress({ indexNumber, address, onDelete }) {
    const [loading, setLoading] = useState(false);

    async function handleSubmitForm() {
        setLoading(true);

        if (typeof onDelete === 'function') {
            onDelete(address);
        }

        setLoading(false);
    }

    return !loading ? (
        <form className="form-content">
            <div className="form-title-button">
                <Typography variant="h6">Endereço {indexNumber + 1}</Typography>

                <button
                    className="button button-red button-address-exist"
                    type='button'
                    onClick={() => handleSubmitForm()}
                >
                    Remover
                </button>
            </div>
            <TextInput
                label="CEP"
                value={address?.address.zip_code}
                className="textField"
                disabled
            />
            <TextInput
                label="Estado"
                value={address?.address.city.id_state.name}
                className="textField"
                disabled
            />
            <TextInput
                label="Cidade"
                value={address?.address.city.name}
                className="textField"
                disabled
            />
            <TextInput
                label="Bairro"
                value={address?.address.district}
                className="textField"
                disabled
            />
            <TextInput
                label="Rua"
                value={address?.address.street}
                className="textField"
                disabled
            />
            <TextInput
                label="Número"
                value={address?.number}
                className="textField"
                disabled
            />
        </form>
    ) : (
        <Loader loading={loading} />
    )
}
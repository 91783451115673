import { ApiService } from '../App/services/ApiService';
import { SET_PARKING } from './parkingReducers';

export function setMaps(city, callback) {
    return dispatch => {
        ApiService.get(`/establishments/list/${city}`).then((response) => {
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback(false);
            }
            if (response.data.success && typeof callback === 'function') {
                dispatch({
                    type: SET_PARKING,
                    payload: response['data'].resultSet
                });
                return callback(true);
            }
            if (typeof callback === 'function') {
                dispatch({
                    type: SET_PARKING,
                    payload: response.resultSet
                });
                callback(false);
            }
        })
    }
}
import 'date-fns';
import React from 'react';
// import DateFnsUtils from '@date-io/date-fns';
// import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';

// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; 

// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// import { ptBR } from "date-fns/locale";

export default function InputKeyboardDatePicker({ ...props }) {
    const {
        id,
        label,
        value,
        onChange,
        inputVariant,
        className,
        format,
        disableFuture,
    } = props;

    return (
         <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                disableToolbar
                variant="inline"
                inputVariant={inputVariant ? inputVariant : 'outlined'}
                autoOk
                id={id ? id : `date-picker-inline-${label.trim()}`}
                margin="normal"
                label={label}
                format={format ? format : 'dd/MM/yyyy'}
                value={value}
                disableFuture={disableFuture}
                onChange={(date) => { onChange(date) }}
                className={className}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                helperText={''}
                slotProps={{ textField: { size: 'small' } }}
                sx={{"& .MuiInputBase-input": {
                  height: "30px"
                }}}
                {...props}
            />
        </LocalizationProvider>
    )
}
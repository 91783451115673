import { format, compareDesc } from 'date-fns';
import { ApiService } from '../App/services/ApiService';
import { SET_HISTORY, FILTER_HISTORY, RESET_HISTORY } from './historyReducers';

export function setHistory(where, callback) {
    return dispatch => {
        ApiService.get(`/reports/client/parking-list?offset=${where.offset}&limit=${where.limit}`).then((response) => {
            if (!response) {
                dispatch({
                    type: RESET_HISTORY
                });

                if (typeof callback === 'function')
                    callback(false);
            }

            if (response?.data.success) {
                const history = response['data'].resultSet.sort(compareDesc);

                dispatch({
                    type: SET_HISTORY,
                    payload: history
                });

                if (typeof callback === 'function')
                    callback(true);
            }
        })
    }
}

export function filterHistory(date, callback) {
    const initialDate = format(date.initialDate, 'yyyy-MM-dd');
    const finalDate = format(date.finalDate, 'yyyy-MM-dd');

    return dispatch => {
        ApiService.get(`/reports/client/parking-list?initDate=${initialDate}&finalDate=${finalDate}`).then((response) => {
            dispatch({
                type: RESET_HISTORY
            });

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback(false);
            }

            if (response?.data.success) {
                const history = response['data'].resultSet.sort(compareDesc);

                dispatch({
                    type: FILTER_HISTORY,
                    payload: history
                });

                if (typeof callback === 'function')
                    callback(true);
            }
        })
    }
}

export function resetHistory() {
    return {
        type: RESET_HISTORY
    }
}
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import FormikTextInput from '../../App/components/FormikTextInput';
import FormikSelect from '../../App/components/FormikSelect';
import MUIButton from '../../App/components/MUIButton';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import FormikTextInputPassword from '../../App/components/FormikTextInputPassword';
import Loader from '../../App/components/Loader';
import Validation from '../../App/utils/Validation';
import GreatMask from '../../App/utils/GreatMask';
import { createUser } from '../CreateAccountActions';
import ConfirmTermoDialog from './ConfirmTermoDialog';
import './styles.css';
import Toast from '../../App/utils/Toast';
import { vehicleTypes, getIcon } from '../../App/utils/typesVehicles';
import { vehicleExist } from '../../Vehicle/VehicleActions';

export default function FormNewAccount({ onConfirm, cancel }) {

    const dispatch = useDispatch();
    const [openTermo, setOpenTermo] = useState(false);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });
    const [vehicleExistDataBase, setVehicleExist] = useState(false); // veiculo ja existe no banco, vai ser apenas vinculado ao usuario

    const formik = useFormik({

        initialValues: {
            name:                   '',
            cpf:                    '',
            birthday:               '',
            cellphone:              '',
            email:                  '',
            password:               '',
            genre_external_id:      'GNR_MASC',
            genre_other:            '',

            zipcode:                '',
            uf:                     '',
            city:                   '',
            district:               '',
            street:                 '',
            number:                 '',
            complement:             '',

            vehicle_id:             '',
            id_vehicle_type:        '',
            plaque:                 '',
            vehicle_description:    '',

        },
        validate: values => {

            const errors = {};

            if (!values.name) {
                errors.name = true;
            }

            if (!values.cpf || !Validation.cpf(GreatMask.onlyNumber(values.cpf))) {
                errors.cpf = true;
            }

            // if (!values.birthday || !Validation.birthdayAdult(values.birthday)) {
                // errors.birthday = true;
            // }

            if (!values.cellphone || GreatMask.onlyNumber(values.cellphone).length !== 11) {
                errors.cellphone = true;
            }

            if (!values.email) {
                errors.email = true;
            }

            if (!values.password || values.password.length < 6) {
                errors.password = true;
            }

            if (values.zipcode.length < 5) {
                errors.zipcode = true;
            }

            if (!values.street) {
                errors.street = true;
            }
            
            if (!values.number) {
                errors.number = true;
            }

            if (values.plaque.length !== 7 || !Validation.plaque(values.plaque)) {
                errors.plaque = true;
            }

            if (!values.vehicle_description) {
                errors.vehicle_description = true;
            }

            return errors;
        },
        onSubmit: values => {

            setOpenTermo(true);
            // handleConfirmTermo(true);
        },
    });

    useEffect(() => {
        setLoading(false)
    }, [])

    function handleConfirmTermo(confirm) {

        setOpenTermo(false);

        if (confirm) {

            Toast.loading('Aguarde...');

            dispatch(createUser(formik.values, (resp) => {
            

                if (typeof resp !== 'boolean' || !resp) {

                    Toast.showToast(resp, '');

                    return;
                }

                Toast.showToast(true, 'Cadastro realizado com sucesso!');

                goToLogin();
            }));
        }
    }

    function goToLogin() {

        formik.resetForm();
        
        typeof onConfirm === 'function' && !modal.open && onConfirm(formik.values.email, formik.values.password);
    }

    function handleCancel() {

        formik.resetForm();
        
        typeof cancel === 'function' && cancel();
    }

    const searchZipcode = async (zipcode) => {
        
        setLoading(true);

        Toast.loading('Aguarde...');
        
        const json = await fetch(`https://viacep.com.br/ws/${zipcode}/json`).then(resp => resp.json());

        if (json.hasOwnProperty('erro')) {

            setLoading(false);
            
            /*return setModal({
                open: true,
                title: 'ERRO!',
                text: 'CEP não existe, informe o CEP corretamente.'
            })*/

            Toast.showToast('Endereço não encontrado.','');

            return
        }
        
        Toast.showToast(true, 'Endereço encontrado.');

        formik.setFieldValue('district', json.bairro)
        formik.setFieldValue('city', json.localidade)
        formik.setFieldValue('street', json.logradouro)
        formik.setFieldValue('uf', json.uf)

        setLoading(false);
    }

    function verifyVehicleExist(plaque) {

        setLoading(true)

        Toast.loading('Aguarde...');

        dispatch(vehicleExist(GreatMask.toUpperCaseOnlyText(plaque), resp => {

            formik.setFieldValue('vehicle_description', '')

            if (typeof resp === 'boolean' && resp) {

                Toast.dismiss()

                setLoading(false)

                return
            }

            if (typeof resp === 'object') {

                Toast.dismiss()

                setLoading(false)

                if (resp[0].id_user_owner) {

                    Toast.showToast('Veículo já cadastrado para outro usuário.', '')
                }
                else {

                    setVehicleExist(true)

                    formik.setFieldValue('vehicle_id',          resp[0].id);
                    formik.setFieldValue('vehicle_description', resp[0].name);
                    formik.setFieldValue('id_vehicle_type',     resp[0].id_vehicle_type);
                }
                
                return
            }

            Toast.showToast(resp, '')

            setLoading(false)

            setModal({
                
                open: true,
                title: "Aviso",
                text: resp
            })
        }))
    }

    return (
        <>
            <form className="form" onSubmit={formik.handleSubmit}>

                <div className="half-div">
                    <FormikTextInput
                        label="Nome"
                        name="name"
                        value={formik.values.name}
                        onChange={(e) => formik.setFieldValue('name', e.target.value.toUpperCase())}
                        className='full-input'
                        required
                        error={formik.errors.name}
                    />
                    {/*<FormikTextInput
                        label="Data de nascimento"
                        name="birthday"
                        value={formik.values.birthday}
                        onChange={(e) => formik.setFieldValue('birthday', GreatMask.dateBR(e.target.value))}
                        InputLabelProps={{ maxLength: 10 }}
                        className='half-input'
                        required
                        error={formik.errors.birthday}
                    /> */}
                
                    <FormikSelect
                        label="Gênero"
                        name="genre_external_id"
                        value={formik.values.genre_external_id}
                        onChange={formik.handleChange}
                        className="half-input2"
                    >
                        <MenuItem value="GNR_MASC">Masculino</MenuItem>
                        <MenuItem value="GNR_FEMI">Feminino</MenuItem>
                        <MenuItem value="GNR_OTHER">Outros</MenuItem>
                    </FormikSelect>

                    {formik.values.genre_external_id === 'GNR_OTHER' && (
                        <FormikTextInput
                            label="Gênero"
                            name="genre_other"
                            value={formik.values.genre_other}
                            onChange={formik.handleChange}
                            className='half-input'
                            required
                        />
                    )}
                </div>

                <div className="half-div">
                    <FormikTextInput
                        label="CPF"
                        name="cpf"
                        value={formik.values.cpf}
                        onChange={(e) => formik.setFieldValue('cpf', GreatMask.cpf(e.target.value))}
                        className='half-input'
                        InputLabelProps={{ maxLength: 14 }}
                        required
                        error={formik.errors.cpf}
                    />
                    <FormikTextInput
                        label="Celular"
                        name="cellphone"
                        value={formik.values.cellphone}
                        onChange={(e) => formik.setFieldValue('cellphone', GreatMask.toPhone(e.target.value))}
                        InputLabelProps={{ maxLength: 14 }}
                        className='half-input'
                        required
                        error={formik.errors.cellphone}
                    />
                </div>

                <div className="half-div">
                    <FormikTextInput
                        label="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className="full-input"
                        required
                        error={formik.errors.email}
                    />

                </div>

                <div className="half-div">

                    <FormikTextInputPassword
                        label="Senha"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        className="full-input"
                        required
                        error={formik.errors.password}
                    />
                </div>

                <Divider/>

                <div className="half-div" style={{"marginTop": "10px"}}>

                    <FormikTextInput
                        label="CEP"
                        name="zipcode"
                        value={formik.values.zipcode}
                        onChange={(e) => {
                            const zipcodeNumber = GreatMask.onlyNumber(e.target.value)
                            formik.setFieldValue('zipcode', zipcodeNumber)
                            zipcodeNumber.length === 8 && searchZipcode(zipcodeNumber)
                        }}
                        InputLabelProps={{ maxLength: 8 }}
                        className="half-input2"
                        required
                        error={formik.errors.zipcode}   
                    />

                    <FormikTextInput
                        label="Rua"
                        name="street"
                        value={formik.values.street}
                        onChange={formik.handleChange}
                        className="full-input"
                        disabled
                    />
                </div>

                <div className="half-div">
                     <FormikTextInput
                        label="Cidade"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        className="full-input"
                        disabled
                    />

                    <FormikTextInput
                        label="Estado"
                        name="uf"
                        value={formik.values.uf}
                        onChange={formik.handleChange}
                        InputLabelProps={{ maxLength: 2 }}
                        className="half-input2"
                        disabled
                    />
                </div>

                <div className="half-div">
                    <FormikTextInput
                        label="Bairro"
                        name="district"
                        value={formik.values.district}
                        onChange={formik.handleChange}
                        className="full-input"
                        disabled
                    />

                    <FormikTextInput
                        label="Número"
                        name="number"
                        value={formik.values.number}
                        onChange={formik.handleChange}
                        className="half-input2"
                        required
                        error={formik.errors.number}
                    />
                </div>

                <div className="half-div">

                    <FormikTextInput
                        label="Complemento"
                        name="complement"
                        value={formik.values.complement}
                        onChange={formik.handleChange}
                        className="full-input"
                    />
                </div>

                <Divider/>

                <div className="half-div" style={{"marginTop": "10px"}}>

                    <FormikSelect
                        label="Tipo do veículo"
                        name="id_vehicle_type"
                        value={formik.values.id_vehicle_type}
                        onChange={(e) => {
                            formik.setFieldValue('id_vehicle_type', vehicleTypes[e.target.value - 1].id_vehicle_type);
                            formik.setFieldValue('name_type_vehicle', vehicleTypes[e.target.value - 1].name);
                        }}
                        className="half-input"
                        required
                        disabled={vehicleExistDataBase ? true : false}
                    >
                        {vehicleTypes.map((item) => <MenuItem value={item.id_vehicle_type} key={item.id_vehicle_type} className="menu">
                            <div className="menu-itens">
                                {`${item.name}`}
                            </div>
                        </MenuItem>
                        )}

                    </FormikSelect>

                    <FormikTextInput
                        label="Placa"
                        name="plaque"
                        value={formik.values.plaque.toUpperCase()}
                        
                        onChange={(e) => { 
                            let plaque = e.target.value 
                            formik.setFieldValue('plaque', plaque) 
                        }}

                        InputLabelProps={{ maxLength: 7 }}
                        className="half-input"
                        required
                        error={formik.errors.plaque}
                    />
                </div>

                <div className="half-div">

                    <FormikTextInput

                        label="Descrição do veículo"
                        name="vehicle_description"
                        value={formik.values.vehicle_description.toUpperCase()}
                        onChange={formik.handleChange}
                        className="full-input"
                        required
                        disabled={vehicleExistDataBase ? true : false}
                        error={formik.errors.vehicle_description}
                    />
                </div>

                <div className="button-content">
                    <MUIButton onClick={handleCancel} buttonType="red">
                        Cancelar
                    </MUIButton>
                    <MUIButton
                        type="submit"
                        buttonType="green"
                        disabled={formik.isValid === true && formik.dirty === true ? false : true}
                    >
                        {formik.isValid === true && formik.dirty === true ? "QUERO ME CADASTRAR!" : "CADASTRAR"}
                    </MUIButton>
                </div>

            </form>

            <ConfirmTermoDialog 
                open={openTermo} 
                onConfirm={handleConfirmTermo} />
            
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
            {loading && <Loader loading={loading} />}
        </>
    )
}
import { format, compareDesc } from 'date-fns';
import { ApiService } from '../App/services/ApiService';
import { SET_EVENT, FILTER_EVENT, RESET_EVENT, SET_CITIES } from './eventReducers';

export function setEvent(where, callback) {

    return dispatch => {

        ApiService.get(`/event/?offset=${where.offset}&limit=${where.limit}`).then((response) => {

            if (!response) {

                dispatch({
                    type: RESET_EVENT
                });

                if (typeof callback === 'function')
                    callback(false);
            }

            if (response?.data.success) {

                const event = response['data'].resultSet.sort(compareDesc);

                dispatch({
                    type: SET_EVENT,
                    payload: event
                });

                if (typeof callback === 'function')
                    callback(true);
            }
        })
    }
}

export function filterEvent(event_date_id, city, date, callback) {

    const initialDate = format(date.initialDate, 'yyyy-MM-dd');
    const finalDate = format(date.finalDate, 'yyyy-MM-dd');

    // console.log(event_id)

    let url = `/event?city=${city}`

    if (event_date_id)
        url = `/event?id=${event_date_id}&city=${city}`

    return dispatch => {

        // ApiService.get(`/event?city=${city}&initDate=${initialDate}&finalDate=${finalDate}`).then((response) => {
        ApiService.get(url).then((response) => {

            dispatch({

                type: RESET_EVENT
            });

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback(false);
            }

            // console.log(response['data'].resultSet[0].length)

            if (response?.data.success) {

                const event = response['data'].resultSet.sort(compareDesc);

                dispatch({
                    
                    type: FILTER_EVENT,
                    payload: event
                });

                if (typeof callback === 'function')
                    callback(event);
            }
            else
                callback(response.data.message);
        })
    }
}

export function newTicket (data, callback) {

    return dispatch => {

        ApiService.post('/event/create/ticket', {

            vehicle:            data.vehicle,
            event_date_value:   data.event_date_value,

        }).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }

            if (typeof response === 'object' && response.data.success) {

                const result = response['data'].resultSet[0];

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }

}

export function updateTicket (data, callback) {

}

export function resetEvent() {

    return {
        type: RESET_EVENT
    }
}

export function setCities(callback) {

    return dispatch => {

        ApiService.get(`/event/cities`).then((response) => {

            dispatch({
                    type: RESET_EVENT
                });

            if (!response) {

                if (typeof callback === 'function')
                    callback(false);
            }

            if (response?.data.success) {

                const cities = response['data'].resultSet.sort(compareDesc);

                dispatch({
                    type: SET_CITIES,
                    payload: cities
                });

                if (typeof callback === 'function')
                    callback(true);
            }
        })
    }
}
import { combineReducers } from 'redux';

import { createAccountReducer } from '../../Login/CreateAccountReducer';
import { userReducer } from '../../MyAccount/userReducers';
import { vehicleReducer } from '../../Vehicle/VehicleReducers';
import { historyReducer } from '../../History/historyReducers';
import { parkingReducer } from '../../Parking/parkingReducers';
import { eventReducer } from '../../Event/eventReducers';
import { ticketReducer } from '../../Ticket/ticketReducers';
import { paymentCardReducer } from '../../PaymentCard/paymentCardReducers';

const Reducers = combineReducers({
    createAccount: createAccountReducer,
    user: userReducer,
    vehicle: vehicleReducer,
    history: historyReducer,
    parking: parkingReducer,
    event: eventReducer,
    ticket: ticketReducer,
    cities: eventReducer,
    paymentCard: paymentCardReducer,
});

export default Reducers;
import { ApiService } from '../App/services/ApiService';
import StorageService from '../App/services/StorageService';
import { SET_USER } from '../MyAccount/userReducers';

export function login(username, password, callback) {

    return dispatch => {

        ApiService.post('/authentication/log-in', { username, password }).then((response) => {

            window.localStorage.clear();

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Dados inconsistentes.');
            } 

            if (response.data?.success) {

                if (response['data'].resultSet[0].id_access_group !== 3) {
                    return callback('Você não tem permissão para acessar.')
                }

                StorageService.add('token', response.headers['authorization-token']);
                StorageService.add('refresh', response.headers['refresh-token']);

                dispatch({

                    type: SET_USER,
                    payload: {
                        user: response['data'].resultSet[0],
                        address: response['data'].resultSet[0].userAddress
                    }
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {
                if (typeof callback === 'function')
                    callback('Email e/ou senha incorreto.');

            }
        })
    }
}

export function logout(callback = () => { }) {

    return () => {
        
        ApiService.post('/authentication/log-out', {}).then(() => {

            window.localStorage.clear();

            if (typeof callback === 'function')
                callback(true);
        })

    }
}
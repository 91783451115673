import { format } from 'date-fns';
import { ApiService } from '../App/services/ApiService';
import GreactMask from '../App/utils/GreatMask';
import {
    SET_LIST_PAYMENTCARD,
    ADD_LIST_PAYMENTCARD,
    RESET_PAYMENTCARD,
    DELETE_PAYMENTCARD
} from './paymentCardReducers';

export function list(where, callback) {

    const currentDate = format(new Date(), 'yyyy-MM-dd')

    // resetPaymentCard()

    return dispatch => {

        ApiService.get(`/users/payment-card`).then((response) => {

            // console.log(response)

            if (!response) 
                return callback(false);

            if (typeof response === 'object' && response?.data?.success) {

                dispatch({

                    type: SET_LIST_PAYMENTCARD,
                    payload: response['data'].resultSet,
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }
}

export function deletePaymentCard(paymentCard, callback) {

    return dispatch => {

        ApiService.delete('/users/payment-card/'+paymentCard.id, {

            id: paymentCard.id,            

        }).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }

            if (typeof response === 'object' && response?.success) {

                dispatch({

                    type: DELETE_PAYMENTCARD,

                    payload: {

                        affectedRows: response.affectedRows,
                        id: paymentCard.id
                    }
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {
                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }
}


export function resetPaymentCard() {

    return dispatch => {

        dispatch({
            type: RESET_PAYMENTCARD,
        })
    }
}

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Loader from '../App/components/Loader';
import MUIButton from '../App/components/MUIButton';
import MainWithData from '../App/components/MainWithData';
import MainWithoutData from '../App/components/MainWithoutData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import InputKeyboardDatePicker from '../App/components/InputKeyboardDatePicker';
import { filterTicket, setTicket, payTicket, filterEventDateValue } from './ticketActions';
import TicketTable from './components/TicketTable';
import './styles.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Toast from '../App/utils/Toast';
import { ToastContainer } from 'react-toastify';
import { addMonths } from 'date-fns';
import FormUpdateTicketDialog from './components/FormUpdateTicketDialog'

export default function Ticket() {

    const { user } = useSelector((state) => state.user);
    const { ticket, affectedRows } = useSelector((state) => state.ticket);
    const [ eventDateValue, setEventDateValue] = useState();
    const dispatch = useDispatch();
    const [initialDate, setInitialDate] = useState(() => {
        const today = new Date();
        const makeDate = new Date();
        return makeDate;
    });

    const [updateTicket, setUpdateTicket] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState();

    const date = new Date();
    const newDate = addMonths(date, 2);

    const [finalDate, setFinalDate] = useState(date);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const openInNewTab = (url) => { window.open(url, '_blank', 'noreferrer'); };

    useEffect(() => {

        // if (affectedRows === 0)
            dispatch(filterTicket({ initialDate, finalDate }, () => { }));

        setLoading(false)

    }, [dispatch, affectedRows])


    function handleFilterTicket() {

        Toast.loading('Aguarde...');

        setLoading(true);

        dispatch(filterTicket({ initialDate, finalDate }, (resp) => {

            setLoading(false);

            if (typeof resp === 'string') {

                Toast.showToast(resp, '')
            }

            if (typeof resp === 'boolean' && !resp) {

                Toast.showToast(resp, '')

                return setModal({

                    open: true,
                    title: 'ERRO!',
                    text: 'Erro no envio das datas para filtrar.'
                })
            }

            Toast.dismiss();
        }));
    }

    function handleUpdateTicket (selectedTicket) {

        setSelectedTicket(selectedTicket)

        Toast.loading('Aguarde...');

        setLoading(true);
        
        dispatch(filterEventDateValue({ 

            eventDate : selectedTicket.eventDateValue.eventDate.id,
        }, 
        (resp) => {

            setLoading(false);

            if (typeof resp === 'string') {

                Toast.showToast(resp, '')
            }

            if (typeof resp === 'object') {

                setEventDateValue(resp.resultSet)
                setUpdateTicket(true)
            }

            Toast.dismiss();
        }));

    }

    function handlePayTicket(ticket) {

        Toast.loading('Aguarde...');

        setLoading(true);
        
        dispatch(payTicket({ 

            ticket_id : ticket.id,
            name:       user.name,
            cpf:        user.cpf,
            phone:      user.phone_number, 
            email:      user.email, 
        }, 
        (resp) => {

            setLoading(false);

            if (typeof resp === 'string') {

                Toast.showToast(resp, '')
            }

            if (typeof resp === 'object') {

                // console.log(resp)

                if (resp.invoice_url) {

                    Toast.showToast(true, 'Cobrança gerada com sucesso!')

                    openInNewTab(resp.invoice_url)
                    
                     /*dispatch(openLink(resp.invoice_url), 
                        
                        (resp) => { 
                        
                        }
                    )*/
                }
            }

            Toast.dismiss();
        }));
    }

    function getTableData() {

        if (ticket.length === 0) {

            return (

                <MainWithoutData title="Lista dos Tickets" />
            )
        }

        return (

            <MainWithData title="Lista dos Tickets">
                <TicketTable 
                resultSet={ticket} 
                updateTicket={handleUpdateTicket}
                payTicket={handlePayTicket}/>
            </MainWithData>
        )
    }

    return (
        <Grid container>
            
            <ToastContainer />

            {updateTicket && (
                <FormUpdateTicketDialog
                    open={updateTicket}
                    setOpen={() => setUpdateTicket(false)}
                    ticket={selectedTicket}
                    eventDateValue={eventDateValue}
                />
            )}

            <Grid item xs={12}>

                <Card id="header-container" sx={{ overflow: "auto" }}>

                    <div className="title-content">
                        <Typography variant="h5">Meus Tickets</Typography>
                    </div>
                    
                    <div className="history-filter-content1">

                        <div className="div-column">

                            <InputKeyboardDatePicker
                                label="Data inicial"
                                value={initialDate}
                                onChange={(date) => setInitialDate(date)}
                                className="filter-itens"
                            />
                            &nbsp;
                            &nbsp;
                            <InputKeyboardDatePicker
                                label="Data final"
                                value={finalDate}
                                onChange={(date) => setFinalDate(date)}
                                className="filter-itens"
                                disableFuture={false}
                            />
                        </div>

                        <div className="div-column">
                            <MUIButton
                                color="primary"
                                onClick={handleFilterTicket}>

                                Filtrar

                            </MUIButton>
                        </div>
                    </div>
                </Card>
            </Grid>
            
            <Grid item xs={12} >
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Grid>
    );
}
import React from 'react'; 
import { useColors } from "../../App/hooks/useColors";
    
export const EventVacancyStatus = ({ qty }) => {
    
    const colors = useColors();

    return (

            <pre style={{
                border: 'none',
                borderRadius: 8,
                backgroundColor: colors.redCS,
                color: qty === 1 ? '#000' : '#FFF',
                padding: 4,
                margin: 0,
                fontFamily: "Roboto"
            }}>
            
            Esgotado

            </pre>
    );
}
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Loader from '../App/components/Loader';
import MUIButton from '../App/components/MUIButton';
import AlertModalDialog from '../App/components/AlertModalDialog';
import InputKeyboardDatePicker from '../App/components/InputKeyboardDatePicker';
import './styles.css';

export default function DeleteAccountDetails() {

    const dispatch = useDispatch();
    
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });


    return (
        <Grid container>
            <Grid item xs={12}>
                <Card id="header-container">
                    <div className="title-content">
                        <Typography variant="h4" style={{color: "#af2a12"}}>Excluir Conta</Typography>
                        <Typography variant="h6">Para excluir sua conta permanentemente e todos seus dados cadastrados, faça Login e acesse o menu 'Minha Conta'
                        e clique no botão 'Excluir Conta'. </Typography>
                    </div>

                    <div className="history-filter-content">

                    </div>
                </Card>
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Grid>
    );
}
import React from 'react'; 
import { useColors } from "../../App/hooks/useColors";
    
export const TicketStatus = ({ status, msg }) => {
    
    const colors = useColors();

    function color(status) {

        switch (status) {

            case 'AGUARDANDO_PAGAMENTO': return colors.yellowCS;
            case 'COBRANCA_GERADA': return colors.yellowCS;
            case 'EXPIRADO': return colors.redCS;
            case 'PAGO': return colors.greenCS;
            case 3: return colors.greenCS;

            default: return '#FFF'
        }
    }

    return (
        <pre style={{
            border: 'none',
            borderRadius: 8,
            backgroundColor: color(status),
            padding: 4,
            margin: 0,
            fontFamily: "Roboto",
        }}>
        
        {msg}

        </pre>
    )
}
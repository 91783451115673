import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";
import Grid from '@material-ui/core/Grid';
import MainWithoutData from '../App/components/MainWithoutData';
import MainWithData from '../App/components/MainWithData';
import Loader from '../App/components/Loader';
import ValuesWidget from './components/ValuesWidget';
import SummaryHistoryTable from './components/SummaryHistoryTable';
import { list } from '../Vehicle/VehicleActions';
import { setHistory } from '../History/historyActions';
import './styles.css';

export default function Dashboard() {
    const { resultSet, where, affectedRows } = useSelector((state) => state.vehicle);
    const { user } = useSelector((state) => state.user);
    const { history } = useSelector((state) => state.history);
    const usehistory = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!user.hasOwnProperty('id')) {
            window.localStorage.clear();
            usehistory.push('/');
        } else {
            function getLists() {
                dispatch(list({ offset: where.offset, limit: where.limit }, (resp) => {
                    if (resp)
                        dispatch(setHistory({ offset: 0, limit: 5 }), () => { });
                }));
            }
            if (affectedRows === 1) {
                getLists();
            }
            setLoading(false);
        }
    }, [user, usehistory, affectedRows, dispatch, where]);

    function getTableData() {
        
        if (history.length === 0)
            return <MainWithoutData title="Histórico de uso" />

        return (
            <MainWithData title="Histórico de uso">
                <SummaryHistoryTable resultSet={history} />
            </MainWithData>
        )
    }

    return (
        <div id="dashboard-container">
            <Grid container spacing={2} id="grid-container">
                <Grid item xs={12} xl={12} sm={6} md={6}>
                    <ValuesWidget title="Total de Veículos" values={resultSet.length} onClickButton='/vehicle' />
                </Grid>
                <Grid item xs={12} xl={12} sm={6} md={6}>
                    <ValuesWidget title="Estacionamentos utilizados" values={history.length} onClickButton='/history' />
                </Grid>

            </Grid>

            {!loading ? getTableData() : (<Loader loading={loading} />)}
        </div>
    );
}
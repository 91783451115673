import { ApiService } from "../App/services/ApiService";
import { SET_ID } from "./CreateAccountReducer";
import GreatMask from "../App/utils/GreatMask";

export function createUser(data, callback) {

    // console.log(data)

    const day = data.birthday.substring(0, 2);
    const month = data.birthday.substring(3, 5);
    const year = data.birthday.substring(6);

    const newBirthday = `${year}-${month}-${day}`;

    return (dispatch) => {

        ApiService.post('/authentication/register/client',

            data.genre_other === '' ? {

                "user": {
                    "email":                data.email,
                    "phone_number":         GreatMask.onlyNumber(data.cellphone),
                    "password":             data.password
                },

                "person": {
                    "name":                 data.name,
                    "document":             GreatMask.onlyNumber(data.cpf),
                    "genre_external_id":    data.genre_external_id
                },

                "address": {
                    "zip_code":             data.zipcode,
                    "main":                 true,
                    "number":               data.number,
                    "complement":           data.complement,
                },
                "vehicle": {
                    "vehicle_id":           data.vehicle_id === '' ? null : data.vehicle_id,
                    "plaque":               data.plaque,
                    "vehicle_description":  data.vehicle_description.toUpperCase(),
                    "id_vehicle_type":      Number(data.id_vehicle_type),
                }
            } : {
                "user": {
                    "email":                data.email,
                    "phone_number":         GreatMask.onlyNumber(data.cellphone),
                    "password":             data.password
                },
                "person": {
                    "name":                 data.name,
                    "document":             GreatMask.onlyNumber(data.cpf),
                    "genre_external_id":    data.genre_external_id,
                    "genre_other":          data.genre_other
                },
                "address": {
                    "zip_code":             data.zipcode,
                    "main":                 true,
                    "number":               data.number,
                    "complement":           data.complement,
                },
                "vehicle": {
                    "vehicle_id":           data.vehicle_id === '' ? null : data.vehicle_id,
                    "plaque":               data.plaque,
                    "vehicle_description":  data.vehicle_description.toUpperCase(),
                    "id_vehicle_type":      Number(data.id_vehicle_type),
                }

            }).then((response) => {

                // console.log(response.data)

                if (typeof response !== 'object' && typeof callback === 'function') {

                    return callback('Dados inconsistentes.');
                }

                if (response?.data.success && typeof callback === 'function') {

                    dispatch({
                        type: SET_ID,
                        payload: {
                            external_id: response.data.resultSet[0].external_id,
                            phone_number: GreatMask.onlyNumber(data.cellphone),
                        }
                    });

                    return callback(true);
                }

                if (typeof callback === 'function')
                    callback(response.data.message);
            })
    }
}

export function validadeCode(validadeCodes, callback) {

    return () => {

        ApiService.post('/authentication/confirm_sms', {

            check_code: validadeCodes.code,
            user_externalid: validadeCodes.external_id

        }).then((response) => {

            // console.log(response)

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Dados inconsistentes.');
            }

            if (response?.data.success && typeof callback === 'function') {
                return callback(true);
            }
            if (typeof callback === 'function')
                callback('Dados inconsistentes, confira seus dados e envie novamente');

        })

    }
}
import { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { ENUM_CREATE_ACCOUNT } from '../enum';
import FormNewAccount from './FormNewAccount';
import FormSecurityCode from './FormSecurityCode';
import './styles.css';

export default function CreateAccountDialog({ open, cancel, onNewAccountOk }) {

    // const [step, setStep] = useState(ENUM_CREATE_ACCOUNT.SECURITY_CODE);
    const [step, setStep] = useState('');

    useEffect(() => {

        setStep(ENUM_CREATE_ACCOUNT.FORM);

    }, [])

    function handleToSecurityCode() {

        setStep(ENUM_CREATE_ACCOUNT.SECURITY_CODE);
    }

    function handleToLogin(email, password) {

        setStep(ENUM_CREATE_ACCOUNT.FORM);

        // se novo cadastro deu certo, ja efetua o login
        if (email && password) {

            onNewAccountOk(email, password)
        }
        
        typeof cancel === 'function' && cancel();
    }

    function getComponent() {

        switch (step) {

            case ENUM_CREATE_ACCOUNT.FORM: return (

                // <FormNewAccount onConfirm={handleToSecurityCode} cancel={handleToLogin} />
                <FormNewAccount onConfirm={handleToLogin} cancel={handleToLogin} />
            );

            case ENUM_CREATE_ACCOUNT.SECURITY_CODE: return (
                <FormSecurityCode onConfirm={handleToLogin} />
            );

            default: return (
                <FormNewAccount onConfirm={handleToSecurityCode} />
            );
        }
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm">
            
            <Typography variant="h5" className="dialog-title-left">Novo cadastro</Typography>

            <DialogContent style={{padding: "5px", border: "0px solid"}}>

                <div id="newaccount-container">
                    {getComponent()}
                </div>
            </DialogContent>
        </Dialog>
    )
}
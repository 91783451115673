import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextInput from '../../App/components/TextInput';
import MUIButton from '../../App/components/MUIButton';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import GreatMask from '../../App/utils/GreatMask';
import { validadeCode } from '../CreateAccountActions';
import './styles.css';

export default function FormSecurityCode({ onConfirm }) {

    const user = useSelector((state) => state.createAccount);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [securityCode, setSecurityCode] = useState('');
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        setLoading(false)
    }, [])

    function handleConfirm() {

        setLoading(true);
        
        dispatch(validadeCode({
            external_id: user.external_id,
            code: securityCode
        }, (resp) => {
            setLoading(false);
            if (typeof resp !== 'boolean' || !resp) {
                return setModal({
                    open: true,
                    title: 'ERRO',
                    text: resp
                })
            }

            setModal({
                open: true,
                title: 'Cadastro realizado com sucesso!',
                text: 'Parabéns, agora você pode estacionar seu veículo de forma fácil!'
            })

            goToLogin();
        }));
    }

    function goToLogin() {
        typeof onConfirm === 'function' && !modal.open && onConfirm();
    }

    const phoneNumber = user.phone_number.substring(user.phone_number.length - 4, user.phone_number.length)

    return (
        <>
            <p className="subtitle">
                Verifique a mensagem que enviamos via SMS para o número (**)****-{phoneNumber} e digite o código de segurança que está na mensagem.
            </p>

            <p className="subtitle-warning">
                Para a sua segurança, não compartilhe este código de segurança.
            </p>

            <form className="form" onSubmit={handleConfirm}>
                <div className="half-div">
                    <TextInput
                        label="Código de segurança"
                        value={securityCode}
                        onChange={(e) => e.target.value.length < 7 && setSecurityCode(GreatMask.onlyNumber(e.target.value))}
                        className="full-input"
                        required
                    />
                </div>
                <div className="button-content">
                    <MUIButton onClick={goToLogin} buttonType="red">
                        Cancelar
                    </MUIButton>
                    <MUIButton type="submit" buttonType="green" disabled={securityCode.length === 6 ? false : true}>
                        Confirmar
                    </MUIButton>
                </div>
            </form>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
            {loading && <Loader loading={loading} />}
        </>
    )
}
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Motorcycle from "@material-ui/icons/Motorcycle";
import LocalShipping from "@material-ui/icons/LocalShipping";
import DirectionsBus from "@material-ui/icons/DirectionsBus";

export const vehicleTypes = [
    { id_vehicle_type: "1", name: "Carro" },
    { id_vehicle_type: "2", name: "Moto" },
    { id_vehicle_type: "3", name: "Caminhonete" },
    // { id_vehicle_type: "4", name: "Ônibus" },
    // { id_vehicle_type: "5", name: "Caminhão" },
];

export function getIcon(icon, iconColor) {
    switch (icon) {
        case 1: return <DirectionsCar style={{ color: iconColor }} />
        case 2: return <Motorcycle style={{ color: iconColor }} />
        case 3: return <LocalShipping style={{ color: iconColor }} />
        case 4: return <DirectionsBus style={{ color: iconColor }} />
        default: return <DirectionsCar style={{ color: iconColor }} />
    }
}

// icons
// icon: 'DirectionsCar'
// icon: 'Motorcycle'
// icon: 'LocalShipping'
// icon: 'DirectionsBus'
import { ApiService } from '../App/services/ApiService';
import GreatMask from '../App/utils/GreatMask';
import {
    CHANGE_USER_EMAIL,
    CHANGE_USER_CELLPHONE,
    CHANGE_USER_ADDRESS,
    DELETE_USER_EMAIL,
    DELETE_USER_CELLPHONE,
    DELETE_USER_ADDRESS,
    RESET_USER
} from './userReducers';

export function createEmailCellphone(newData, callback) {

    const phone = GreatMask.onlyNumber(String(newData.phone_number));
    
    return dispatch => {

        ApiService.post('/users/register/personal-data',

            newData.email === '' || newData.email === undefined ? {
                phone_number: phone
            } : {
                email: newData.email
            }).then((response) => {

                if (typeof response === 'undefined' && typeof callback === 'function') {
                    return callback(false);
                }
                 else if (response?.data.success) {

                    if (newData.email === '' || newData.email === undefined) {

                        dispatch({
                            type: CHANGE_USER_CELLPHONE,
                            payload: { phone_number: phone }
                        });
                    } 
                    else {

                        dispatch({
                            type: CHANGE_USER_EMAIL,
                            payload: { email: newData.email }
                        });
                    }

                    if (typeof callback === 'function')
                        return callback(true);
                } 
                else {

                    alert('Dados inconsistentes, confira os seus dados e tente novamente.')
                    
                    if (typeof callback === 'function')
                        callback(false);
                }
            })

    }
}

export function deleteCellphone(deleteData, callback) {

    return dispatch => {

        ApiService.delete('/users/delete/personal-data', { phone_number: deleteData.phone_number }).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }
            else if (response?.success) {

                dispatch({
                    type: DELETE_USER_CELLPHONE,
                    payload: deleteData
                });

                if (typeof callback === 'function')
                    callback(true);
            }
            else {

                alert('Dados inconsistentes, confira os seus dados e tente novamente.')
                
                if (typeof callback === 'function')
                    callback(false);
            }
        })

    }
}

export function deleteEmail(deleteData, callback) {

    return dispatch => {

        ApiService.delete('/users/delete/personal-data', { email: deleteData.email }).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }
            else if (response?.success) {

                dispatch({
                    type: DELETE_USER_EMAIL,
                    payload: deleteData
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                alert('Dados inconsistentes, confira os seus dados e tente novamente.')
                
                if (typeof callback === 'function')
                    callback(false);
            }
        })

    }
}

export function createUserAddress(data, callback) {

    return dispatch => {

        ApiService.post('/users/register/personal-address', {
            
            zip_code: data.zip_code,
            main: true,
            number: data.number,
            id: data.id

        }).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }

            else if (response?.data.success) {

                dispatch({
                    type: CHANGE_USER_ADDRESS,
                    payload: response['data'].resultSet[0]
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                alert('Dados inconsistentes, confira os seus dados e tente novamente.')
                
                if (typeof callback === 'function')
                    callback(false);
            }
        })

    }
}

export function deleteUserAddress(deleteId, callback) {

    return dispatch => {

        ApiService.delete(`/users/personal-address/${deleteId}`).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            } 
            else if (response?.success) {

                dispatch({
                    type: DELETE_USER_ADDRESS,
                    payload: deleteId
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                // alert('Dados inconsistentes, confira os seus dados e tente novamente.')
                
                if (typeof callback === 'function')
                    callback('Dados inconsistentes, confira os seus dados e tente novamente.');
            }
        })
    }
}

export function recoverPassword(user, callback) {

    return () => {

        ApiService.post('/authentication/forgot-password', {

            username: user

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Erro ao enviar o usuário. Verifique o e-mail e tente novamente.');
            }
            
            if (response.data?.success && typeof callback === 'function') {
                return callback(true);
            }
            
            if (typeof callback === 'function')
                callback('Dados inconsistentes.');
        })
    }
}

export function changePassword(data, callback) {

    return () => {

        ApiService.patch('/authentication/change-password', {

            password: data.password,
            check_code: data.code

        }).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback('Não foi possvel alterar a senha. Verifique o código e tente novamente.');
            }
            if (response.success && typeof callback === 'function') {
                return callback(true);
            }
            if (typeof callback === 'function')
                callback('Dados inconsistentes.');
        })
    }
}

export function deleteAccount(callback) {

    return dispatch => {

        ApiService.delete('/users/delete').then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            } 
            else if (response?.success) {

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }
}


export function resetUser() {

    return dispatch => {

        dispatch({
            type: RESET_USER
        });
    }
}
export const SET_EVENT = 'SET_EVENT';
export const FILTER_EVENT = 'FILTER_EVENT';
export const RESET_EVENT = 'RESET_EVENT';
export const SET_CITIES = 'SET_CITIES';

const INITIAL_STATE = {

    event: [],
    cities: [],
    affectedRows: 1,
    where: {
        offset: 0,
        limit: 10,
    }
}

export function eventReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case SET_EVENT:

            const newEvent = [...state.event, ...action.payload];
            const newOffset = action.payload.length;

            return {
                ...state,
                event: newEvent,
                affectedRows: newOffset < state.where.limit ? 0 : 1,
                where: {
                    ...state.where,
                    offset: state.where.offset + newOffset
                }
            }

        case FILTER_EVENT:

            const filterEvent = [...state.event, ...action.payload];
    
            return {
                ...state,
                event: filterEvent,
            }

        case RESET_EVENT:

            return {
                ...INITIAL_STATE,
            }

        case SET_CITIES:

            const newCity = [...state.cities, ...action.payload];

            return {
                ...state,
                cities: newCity,
            }

        default:
            return state
    }
}
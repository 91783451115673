import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import Loader from '../App/components/Loader';
import MainWithData from '../App/components/MainWithData';
import MainWithoutData from '../App/components/MainWithoutData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import MUIButton from '../App/components/MUIButton';
import { logout } from '../Login/LoginActions';
import VehicleTable from './components/VehicleTable';
import ConfirmVehicleDialog from './components/ConfirmVehicleDialog';
import ConfirmDeleteVehicleDialog from './components/ConfirmDeleteVehicleDialog';
import FormNewVehicleDialog from './components/FormNewVehicleDialog';
import { list, changeAtivo, deleteVehicle,  } from './VehicleActions';
import './styles.css';
import Toast from '../App/utils/Toast';
import { ToastContainer } from 'react-toastify';

export default function Vehicle() {

    const { resultSet, affectedRows, where } = useSelector((state) => state.vehicle);
    const { user } = useSelector((state) => state.user);
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [checkedAtivo, setCheckedAtivo] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogChangeActive, setOpenDialogChangeActive] = useState(false);
    const [openDialogDeleteVehicle, setOpenDialogDeleteVehicle] = useState(false);
    const [selectVehicle, setSelectVehicle] = useState({});
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });
    
    useEffect(() => {

        if (affectedRows === 1) {

            dispatch(list({ offset: where.offset, limit: where.limit }, (response) => {

                if (!response) {

                    dispatch(logout(() => {
                        history.push('/')
                    }))
                }
            }));

            setLoading(false);
        }

        setLoading(false);

    }, [affectedRows, history, where, dispatch]);

    function changeVehicle(vehicle) {

        setSelectVehicle(vehicle);

        setOpenDialog(true);
    }

    function changeAtivoVehicle(vehicle) {

        setSelectVehicle(vehicle);

        setOpenDialogChangeActive(true);
    }

    function handleConfirmChangeActive(change) {

        setOpenDialogChangeActive(false);

        Toast.loading('Aguarde...');

        setLoading(true);

        if (change) {

            dispatch(changeAtivo({

                id: String(selectVehicle.id),
                active: selectVehicle.active
            }, 
            (response) => {

                verifyResponse(response);
            }))
        } 
        else {

            setSelectVehicle({});

            setLoading(false);
        }
    }

    function handleListVehicles() {

        Toast.loading('Aguarde...');

        setLoading(true);

         dispatch(list({ offset: where.offset, limit: where.limit }, (response) => {

        }));

         Toast.dismiss();
        
        setLoading(false);
    }

    function handleDialogDeleteVehicle(vehicle) {

        setSelectVehicle(vehicle);

        setOpenDialogDeleteVehicle(true);
    }

    function handleConfirmDeleteVehicle(confirm) {

        if (confirm) {
            
            Toast.loading('Aguarde...');

            setLoading(true);

             dispatch(deleteVehicle(selectVehicle , (response) => {

                if (response)
                    Toast.showToast(true, 'Excluído com sucesso!')
                else
                    Toast.showToast('Não foi possível excluir do veículo.', '');
                
                setLoading(false);
            }));
        
        }
        else {

            setSelectVehicle({});

            setLoading(false);
        }

        setOpenDialogDeleteVehicle(false)
    }

    function verifyResponse(response) {

        if (response) {

            setSelectVehicle({});

            /*setModal({

                open: true,
                title: 'Sucesso!',
                text: 'Alteração com sucesso!'
            })*/

            Toast.showToast(response, 'Alterado com sucesso!');
        }
        else 
            Toast.showToast('Nao foi possvel alterar os dados.', '')

        setLoading(false);
    }

    function getTableData() {

        let vehicleActive = resultSet.filter((item) => item.active === checkedAtivo)

        if (resultSet.length === 0 || vehicleActive.length === 0)
            return (
                <MainWithoutData
                    title="Lista de veículos"
                    text={`Não há veículos ${checkedAtivo ? 'ativos' : 'inativos'} cadastrados.`}
                />
            )

        return (

            <MainWithData title="Lista de veículos">
                <VehicleTable
                    resultSetActives={checkedAtivo}
                    resultSet={vehicleActive}
                    onChangeVehicle={changeVehicle}
                    onChangeVehicleActive={changeAtivoVehicle}
                    onDeleteVehicle={handleDialogDeleteVehicle}
                    onCancel={() => { setSelectVehicle({}) }}
                />

            </MainWithData>
        )
    }

    function handleOpenCreateRegister() {

        setSelectVehicle({});

        setOpenDialog(!openDialog);
    }

    return (

        <Grid container>

            <ToastContainer />

            {openDialog && (
                <FormNewVehicleDialog
                    open={openDialog}
                    setOpen={() => setOpenDialog(false)}
                    vehicle={selectVehicle}
                />
               
            )}

            {openDialogChangeActive && (
                <ConfirmVehicleDialog open={openDialogChangeActive} onConfirm={handleConfirmChangeActive} vehicle={selectVehicle} />
            )}

            {openDialogDeleteVehicle && (
                <ConfirmDeleteVehicleDialog open={openDialogDeleteVehicle} onConfirm={handleConfirmDeleteVehicle} vehicle={selectVehicle} />
            )}

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            <Grid item xs={12}>

                <Card id="header-container">

                    <div className="header-content">
                        <div className="title-content">
                            <Typography variant="h4">{`Veículos ${checkedAtivo ? 'Ativos' : 'Inativos'}`}</Typography>
                            <Typography variant="h6">Busca, Cadastro e Edição de Veículos</Typography>
                        </div>

                        <Switch
                            checked={checkedAtivo}
                            onChange={(e) => { setCheckedAtivo(!checkedAtivo) }}
                            color="primary"
                            name="checkedB"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>

                    <div className="button-create-vehicle">

                        <MUIButton
                            color="primary"
                            onClick={handleListVehicles}
                        >
                            Listar
                        </MUIButton>

                        <MUIButton
                            color="primary"
                            onClick={handleOpenCreateRegister}
                        >
                            Novo Cadastro
                        </MUIButton>
                    </div>
                </Card>
            </Grid>

            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>
        </Grid>
    );
}
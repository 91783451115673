export const SET_ID = 'SET_ID';

const INITIAL_STATE = {
    external_id: '',
    phone_number: ''
}

export function createAccountReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case SET_ID:

            return {
                ...state,
                external_id: action.payload.external_id,
                phone_number: action.payload.phone_number,
            }
            
        default:
            return state
    }
}
import { format, compareDesc } from 'date-fns';
import { ApiService } from '../App/services/ApiService';
import { SET_TICKET, FILTER_TICKET, RESET_TICKET, UPDATE_TICKET, UPDATE_TICKET_STATUS } from './ticketReducers';

export function setTicket(where, callback) {

    return dispatch => {

        ApiService.get(`/event/tickets`).then((response) => {

            if (!response) {

                dispatch({
                    type: RESET_TICKET
                });

                if (typeof callback === 'function')
                    callback(false);
            }

            if (response?.data.success) {

                const event = response['data'].resultSet.sort(compareDesc);

                dispatch({
                    type: SET_TICKET,
                    payload: event
                });

                if (typeof callback === 'function')
                    callback(true);
            }
        })
    }
}

export function filterTicket(date, callback) {

    const initialDate = format(date.initialDate, 'yyyy-MM-dd');
    const finalDate = format(date.finalDate, 'yyyy-MM-dd');

    return dispatch => {

        ApiService.get(`/event/tickets?initDate=${initialDate}&finalDate=${finalDate}`).then((response) => {

            dispatch({

                type: RESET_TICKET
            });

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback(false);
            }

            // console.log(response)

            if (response?.data.success) {

                const result = response['data'].resultSet.sort(compareDesc);

                // console.log(response.data.resultSet[0])

                dispatch({
                    
                    type: FILTER_TICKET,
                    payload: response.data.resultSet[0]
                });

                if (typeof callback === 'function')
                    callback(true);
            }
        })
    }
}

export function newTicket (data, callback) {

    return dispatch => {

        ApiService.post('/event/create/ticket', {

            vehicle:            data.vehicle,
            event_date_value:   data.event_date_value,
 
        }).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }

            if (typeof response === 'object' && response.data.success) {

                const result = response['data'].resultSet[0];

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }

}

export function payTicket (data, callback) {

    // console.log(data)

    return dispatch => {

        ApiService.post('/event/ticket/payment', 

            data

        ).then((response) => {

            if (!response) {
                return callback(false);
            }

             if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }

            if (typeof response === 'object' && response.data.success) {
                
                // console.log(response)
                // console.log(response['data'])

                const result = response['data'].resultSet[0];

                dispatch({
                    
                    type: UPDATE_TICKET_STATUS,
                    payload: result
                });

                if (typeof callback === 'function')
                    callback(response.data.resultSet[0]);
            } 
            else {

                if (typeof callback === 'function')
                    callback(response.data.message);
            }
        })
    }
}

export function updateTicket (data, callback) {

    return dispatch => {

        ApiService.post('/event/ticket/update', {

            ticket_id:      data.ticket_id,
            vehicle:        data.vehicle,

        }).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }

            if (typeof response === 'object' && response.data.success) {

                const result = response['data'].resultSet[0];

                dispatch({
                    
                    type: UPDATE_TICKET,
                    payload: result
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                if (typeof callback === 'function')
                    callback(response.data.message);
            }
        })
    }
}

export function filterEventDateValue(data, callback) {

    return dispatch => {

        ApiService.get(`/event/event-date-value?event_date=${data.eventDate}`).then((response) => {

            
            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback(false);
            }

            if (response?.data.success) {

                if (typeof callback === 'function')
                    callback(response.data);
            }
        })
    }
}

export function getPixQrCode(payment_id, callback) {

    return dispatch => {

        ApiService.get(`/event/ticket/pix-qrcode/${payment_id}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {
                return callback(false);
            }

            if (response?.data.success) {

                if (typeof callback === 'function')
                    callback(response.data);
            }
        })
    }
}

export function resetEvent() {

    return {
        type: RESET_TICKET
    }
}
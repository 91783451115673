import { format } from 'date-fns';
import { ApiService } from '../App/services/ApiService';
import GreactMask from '../App/utils/GreatMask';
import { vehicleTypes } from '../App/utils/typesVehicles';
import {
    SET_LIST_VEHICLE,
    ADD_LIST_VEHICLE,
    CHANGE_ACTIVE_VEHICLE,
    UPDATE_VEHICLE,
    RESET_VEHICLE,
    DELETE_VEHICLE
} from './VehicleReducers';

export function list(where, callback) {

    const currentDate = format(new Date(), 'yyyy-MM-dd')

    return dispatch => {

        // ApiService.get(`/vehicle/client?initDate=${currentDate}&finalDate=${currentDate}&offset=${where.offset}&limit=${where.limit}`).then((response) => {
        ApiService.get(`/vehicle/client`).then((response) => {

            if (!response) return callback(false);

            if (typeof response === 'object' && response?.data?.success) {

                // console.log(response)

                dispatch({

                    type: SET_LIST_VEHICLE,
                    payload: response['data'].resultSet,
                });

                if (typeof callback === 'function')
                    callback(response['data'].resultSet);
            } 
            else {

                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }
}

export function insert(data, callback) {

    return dispatch => {

        ApiService.post('/users/register/personal-vehicles', [{

            plaque:             GreactMask.toUpperCaseOnlyText(data.plaque),
            id_vehicle_type:    data.id_vehicle_type,
            name:               data.name.toUpperCase(),

        }]).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }

            if (typeof response === 'object' && response.data.success) {

                const result = response['data'].resultSet[0];

                dispatch({

                    type: ADD_LIST_VEHICLE,

                    payload: {
                        active: result.active,
                        id: result.id,
                        id_vehicle_type: result.id_vehicle_type,
                        name: result.name,
                        plaque: result.plaque,
                        vehicle_type: {
                            id: result.id_vehicle_type,
                            name: vehicleTypes[result.id_vehicle_type].name
                        }
                    },
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }
}

export function changeAtivo(data, callback) {

    return dispatch => {

        ApiService.patch('/vehicle/change-active', [{
            id: data.id,
            active: data.active ? false : true

        }]).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }

            if (typeof response === 'object' && response?.success) {

                dispatch({

                    type: CHANGE_ACTIVE_VEHICLE,

                    payload: {
                        active: response.resultSet[0].active,
                        affectedRows: response.affectedRows,
                        id: response.resultSet[0].id
                    }
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {
                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }
}

export function update(data, callback) {

    return dispatch => {

        ApiService.patch('/vehicle/update/client', {

            id:                 data.id,
            name:               data.name.toUpperCase(),
            id_vehicle_type:    data.id_vehicle_type,
            id_user_owner:      data.id_user_owner,

        }).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {

                return callback(false);
            }

            if (typeof response === 'object' && response?.success) {

                dispatch({

                    type: UPDATE_VEHICLE,

                    payload: {
                        name:               response.resultSet[0].name,
                        id_vehicle_type:    response.resultSet[0].id_vehicle_type,
                        affectedRows:       response.affectedRows,
                        id:                 response.resultSet[0].id
                    }
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {

                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }
}

export function deleteVehicle(vehicle, callback) {

    return dispatch => {

        ApiService.patch('/vehicle/delete', {

            id: String(vehicle.id),            

        }).then((response) => {

            if (typeof response === 'undefined' && typeof callback === 'function') {
                return callback(false);
            }

            if (typeof response === 'object' && response?.success) {

                dispatch({

                    type: DELETE_VEHICLE,

                    payload: {

                        active: response.resultSet[0].active,
                        affectedRows: response.affectedRows,
                        id: response.resultSet[0].id
                    }
                });

                if (typeof callback === 'function')
                    callback(true);
            } 
            else {
                if (typeof callback === 'function')
                    callback(false);
            }
        })
    }
}

export function vehicleExist(plaque, callback) {

    return dispatch => {

        ApiService.get(`/vehicle/?&plaque=${plaque}`).then((response) => {

            if (typeof response !== 'object' && typeof callback === 'function') {

                return callback('Erro ao enviar os dados. Confira e tente novamente');
            }

            if (response.data.success && typeof callback === 'function') {

                if (response.data.resultSet.length <= 0) {
                    return callback(true)
                }

                if (response.data.resultSet[0].exit === '')
                    return callback(response.data.message);

                return callback(response.data.resultSet)
            }

            if (typeof callback === 'function')
                return callback(false);

        })
    }
}

export function resetVehicle() {

    return dispatch => {

        dispatch({
            type: RESET_VEHICLE,
        })
    }
}

import React from 'react';
import { useHistory } from 'react-router';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useColors } from "../../App/hooks/useColors";
import './styles.css';

export default function ValuesWidget({ title, values, onClickButton }) {
    const history = useHistory();
    const colors = useColors();

    function handleGoTo() {
        if (typeof onClickButton === 'string') {
            history.push(onClickButton);
        }
    }

    return (
        <Card id="widget-container">
            <CardContent className="widget-card-content">
                <Typography variant="h6">{title}</Typography>
                <div className="content-value">
                    <Typography variant="h3" style={{ color: colors.greenCS }}>{values}</Typography>
                </div>
            </CardContent>
            <CardActions className="widget-card-action">
                <Button size="small" onClick={handleGoTo}>Mais detalhes</Button>
            </CardActions>
        </Card>
    );
}
import axios from 'axios';
import StorageService from './StorageService';
import { history } from './HistoryService';

export const baseURL = 'https://api.prod.estacionefacil.consforsistemas.com.br';
// export const baseURL = 'http://192.168.1.49:3000';

export const ApiService = {

    post: async (uri, data) => {
 
        const endpoint = baseURL + uri;

        if (uri === '') {
            return {};
        }

        const config = await getToken();

        // console.log(data)

        return axios.post(endpoint, data, config).then(response => {

            return response;
        })
        .catch(error => {

            let statusCode = error.response?.status || 0;
            
            return getError(statusCode);
        });
    },

    get: async (uri) => {

        const endpoint = baseURL + uri;

        if (uri === '') {
            return {};
        }

        const config = await getToken();

        return axios.get(endpoint, config).then(response => {

            return response;

        }).catch(async error => {

            let statusCode = error.response?.status || 0;

            return getError(statusCode);
        });
    },
    patch: async (uri, data) => {
        const endpoint = baseURL + uri;

        if (uri === '') {
            return {};
        }

        const config = await getToken();

        return axios.patch(endpoint, data, config).then(response => {
            return response['data'];
        }).catch(error => {
            let statusCode = error.response?.status || 0;
            return getError(statusCode);
        });
    },
    delete: async (uri, data) => {
        const endpoint = baseURL + uri;

        if (uri === '') {
            return {};
        }

        const token = await StorageService.get('token');

        return axios.delete(endpoint, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            withCredentials: true,
            data
        }).then(response => {
            return response['data'];
        }).catch(error => {
            let statusCode = error.response?.status || 0;
            return getError(statusCode);
        });
    },

    asaas: async () => {

        const endpoint = 'https://sandbox.asaas.com/api/v3/customers?cpfCnpj=34085677861';

        const config =  {

            headers: { 
                crossdomain: true,
                access_token: '$aact_YTU5YTE0M2M2N2I4MTliNzk0YTI5N2U5MzdjNWZmNDQ6OjAwMDAwMDAwMDAwMDAwNTU2NDI6OiRhYWNoXzQ2YWY0YmZmLTc4ODgtNDQ5Mi1hODM5LTAwNDQxOTg0MjRhZA==',
            }
        }

         const data = {

            cpfCnpj: '34085677861', 
        };

        return axios.get(endpoint, data, config).then(response => {

            console.log(response)

            return response;

        }).catch(async error => {

            let statusCode = error.response?.status || 0;

            return getError(statusCode);
        });
    },
}

async function getToken() {

    const token = await StorageService.get('token');

    return {

        headers: {
            'Content-Type': 'application/json',
            'Authorization': token
        },
        withCredentials: true,
    }
}

function getError(statusCode) {
    switch (statusCode) {
        case 400:
            return false;
        case 401:
            window.localStorage.clear();
            history.push('/');
            alert('Sessão expirada, por favor realize login novamente');
            return window.location.reload();
        case 403:
            return false;
        default: return statusCode;
    }
}
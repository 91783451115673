import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import TextInput from '../../App/components/TextInput';
import Loader from "../../App/components/Loader";
import GreatMask from '../../App/utils/GreatMask';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import './styles.css';

export default function MyDataTabPanel({ user, onSaveEmailCellphone, onDeleteEmail, onDeleteCellphone, onDeleteAccount }) {

    const [loading, setLoading] = useState(false);
    const [addNewEmail, setAddNewEmail] = useState(false);
    const [addNewCellphone, setAddNewCellphone] = useState(false);
    
    const [form, setForm] = useState({
        name: '',
        cpf: '',
        birthday: '',
        cellphone: '',
        cellphone2: '',
        email: '',
        email2: '',
        password: '',
        genre_external_id: '',
        genre_other: ''
    });
    
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {

        if (user) {

            setForm({
                name: user.name,
                cpf: user.cpf,
                birthday: user.birthday,
                cellphone: String(user.phone_number),
                email: user.email,
                password: user.password,
                genre_external_id: user.genre_external_id,
                genre_other: user.genre_other
            })
        }

        setLoading(false);

    }, [user]);

    function handleSubmitForm(e) {

        e.preventDefault();

        setLoading(true);

        if (typeof form.email2 === 'string' && form.email2 !== '') {

            const exist = user?.emails.filter(item => item.email === form.email2)

            if (exist.length > 0) {

                setModal({
                    open: true,
                    title: 'Aviso!',
                    text: 'Esse email já está cadastro.'
                })
            } 
            else {

                onSaveEmailCellphone(form);
                setAddNewEmail(false);
                setForm({ ...form, email2: '' });
            }

            return setLoading(false);
        }

        if (typeof form.cellphone2 === 'string' && form.cellphone2 !== '') {

            const phone = GreatMask.onlyNumber(form.cellphone2)
            const exist = user?.phone_numbers.filter(item => item.phone_number === phone)

            if (exist.length > 0) {

                setModal({
                    open: true,
                    title: 'Aviso!',
                    text: 'Esse número já está cadastro.'
                })
            } 
            else {

                onSaveEmailCellphone(form);
                setAddNewCellphone(false);
                setForm({ ...form, cellphone2: '' });
            }

            return setLoading(false);
        }

        setModal({
            open: true,
            title: 'Aviso!',
            text: 'Dados inconsistentes.'
        });

        setLoading(false);
    }

    const deleteEmail = (value) => {

        setLoading(true);

        if (typeof onDeleteEmail === 'function')
            onDeleteEmail(value)

        setLoading(false);
    }

    const deleteCellphone = (value) => {

        setLoading(true);

        if (typeof onDeleteCellphone === 'function')
            onDeleteCellphone(value)

        setLoading(false);
    }

    function getGenre(id) {

        switch (id) {
            case 'GNR_MASC': return 'Masculino';
            case 'GNR_FEMI': return 'Feminino';
            case 'GNR_OTHER': return 'Outros';
            default: return '';
        }
    }

    return !loading ? (

        <form className="form-content" onSubmit={handleSubmitForm}>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            <TextInput
                label="Nome"
                value={form.name}
                className="textField"
                disabled
            />
            {form.genre_external_id && (
                <TextInput
                    label="Gênero"
                    value={getGenre(form.genre_external_id)}
                    className="textField"
                    disabled
                />
            )}
            {form.genre_external_id === 'GNR_OTHER' && (
                <TextInput
                    label="Gênero"
                    value={form.genre_other}
                    className="textField"
                    disabled
                />
            )}
            <TextInput
                label="CPF"
                value={GreatMask.cpf(form.cpf)}
                className="textField"
                disabled
            />
            <TextInput
                label="Data de nascimento"
                value={GreatMask.dateUStoBR(form.birthday)}
                className="textField"
                disabled
            />
            <div className="textfield-icon">
                <TextInput
                    label="Celular"
                    value={GreatMask.toPhone(form.cellphone)}
                    className="textField-button"
                    disabled
                />
                {user.phone_numbers && user.phone_numbers?.length < 3 && (
                    <button
                        className={clsx("button", "button-mydata", !addNewCellphone ? "button-green" : "button-red")}
                        type="button"
                        onClick={() => {
                            if (addNewCellphone === true) {
                                setAddNewCellphone(false);
                            } else {
                                if (addNewEmail === true)
                                    setAddNewEmail(false);
                                setAddNewCellphone(true);
                            }
                        }}
                    >
                        {!addNewCellphone ? 'Adicionar Telefone' : 'Fechar'}
                    </button>
                )}
            </div>
            {user.phone_numbers && user.phone_numbers?.length > 1 &&
                user.phone_numbers.map((item, index) => (
                    <div key={index} className="textfield-icon">
                        <TextInput
                            label="Celular"
                            value={GreatMask.toPhone(GreatMask.toPhone(String(item.phone_number)))}
                            className="textField-button"
                            disabled
                        />
                        <button
                            className={clsx("button", "button-mydata", "button-red")}
                            onClick={() => { deleteCellphone(item) }}
                        >
                            Remover
                        </button>
                    </div>
                ))}
            {addNewCellphone && (
                <div className="textfield-icon">
                    <TextInput
                        className="textField-button"
                        value={form.cellphone2}
                        onChange={(e) => {
                            if (GreatMask.onlyNumber(e.target.value).length < 12)
                                setForm({ ...form, cellphone2: GreatMask.toPhone(e.target.value) })
                        }}
                        label="Celular secundário"
                    />
                    <button
                        className={clsx("button", "button-mydata", "button-green")}
                        type='submit'
                    >
                        Cadastrar
                    </button>
                </div>
            )}
            <div className="textfield-icon">
                <TextInput
                    label="Email"
                    value={form.email}
                    className="textField-button"
                    disabled
                />
                {user.emails && user.emails?.length < 3 && (
                    <button
                        className={clsx("button", "button-mydata", !addNewEmail ? "button-green" : "button-red")}
                        type="button"
                        onClick={() => {

                            if (addNewEmail === true) {
                                setAddNewEmail(false);
                            }
                             else {

                                if (addNewCellphone === true)
                                    setAddNewCellphone(false);

                                setAddNewEmail(true);
                            }
                        }}

                        onMouseDown={(e) => e.preventDefault()}
                    >
                        {!addNewEmail ? 'Adicionar E-mail' : 'Fechar'}
                    </button>
                )}
            </div>
            {user.emails && user.emails?.length > 1 &&
                user.emails.map((item, index) => index !== 0 && index !== 3 && (
                    <div key={index} className="textfield-icon">
                        <TextInput
                            label="Email"
                            value={item.email}
                            className="textField-button"
                            disabled
                        />
                        <button
                            className={clsx("button", "button-mydata", "button-red")}
                            onClick={() => { deleteEmail(item) }}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            Remover
                        </button>
                    </div>
                ))}
            {addNewEmail && (
                <div className="textfield-icon">
                    <TextInput
                        value={form.email2}
                        onChange={(e) => { setForm({ ...form, email2: e.target.value }) }}
                        label="E-mail secundário"
                        className="textField-button"
                    />
                    <button
                        className={clsx("button", "button-mydata", "button-green")}
                        type='submit'
                    >
                        Cadastrar
                    </button>
                </div>
            )}

            <button
                    className="button button-red button-address-exist"
                    type='button'
                    onClick={() => { onDeleteAccount() }}
            >
                Excluir Conta
            </button>
        </form>
    ) : (
        <Loader loading={loading} />
    )
}
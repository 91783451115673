import React from 'react';
import { useSelector } from 'react-redux';
import clsx from "clsx";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import MyAccountTab from './components/MyAccountTab';
import './styles.css';
import Toast from '../App/utils/Toast';
import { ToastContainer } from 'react-toastify';
import myAccountImg from '../App/assets/bg.jpg';
import myAccountImg2 from './accountImage.jpeg';

const useStyles = makeStyles((theme) => ({
    avatarContent: {
        width: theme.spacing(15.7),
        height: theme.spacing(15.7),
    },
    large: {
        width: theme.spacing(15),
        height: theme.spacing(15),
        fontSize: '4rem',
        backgroundColor: '#0E773C'
    },
}));

export default function MyAccount() {

    const { user } = useSelector((state) => state.user);
    const classes = useStyles();

    return (

        <Grid container spacing={3} id="myaccount-container">

            <ToastContainer />
        
            <Grid item xs={12} id="image-container">
                <img src="" className="img" alt="Estacione Fácil" height={220} width="100%" />
            </Grid>

            <Grid item xs={12} id="profile-container">
                <div className={clsx(classes.avatarContent, 'avatar-content')}>
                    <Avatar className={classes.large}>{user.hasOwnProperty('id') && user?.name.charAt(0)}</Avatar>
                </div>
                <Typography variant="h4" className="username">{user.name}</Typography>
            </Grid>

            <Grid item xs={12}>
                <MyAccountTab />
            </Grid>
        </Grid>
    );
}
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import './styles.css';

export default function MainWithoutData({ title, text }) {
    return (
        <Card id="without-data">
            {/*<Typography variant="h6">{title}</Typography>*/}
            <div className="no-result-content">
                <Typography variant="subtitle1">
                    {text ? text : 'Que pena... Você não utilizou o estacione fácil até agora =['}
                </Typography>
            </div>
        </Card>
    );
}
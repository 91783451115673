
export const SET_LIST_PAYMENTCARD = 'SET_LIST_PAYMENTCARD';
export const ADD_LIST_PAYMENTCARD = 'ADD_LIST_PAYMENTCARD';
export const RESET_PAYMENTCARD = 'RESET_PAYMENTCARD';
export const DELETE_PAYMENTCARD = 'DELETE_PAYMENTCARD';

const INITIAL_STATE = {

    paymentCardList: [],
    affectedRows: 1,
    where: {
        offset: 0,
        limit: 8,
    }
};

export function paymentCardReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case SET_LIST_PAYMENTCARD:

            // const newPaymentCard = [...state.resultSet, ...action.payload];
            const newPaymentCard = [...action.payload];
            const newOffset = action.payload.length;

            return {

                ...state,
                paymentCardList: newPaymentCard,
                affectedRows: newOffset < state.where.limit ? 0 : 1,
                where: {
                    ...state.where,
                    offset: state.where.offset + newOffset
                }
            }

        case ADD_LIST_PAYMENTCARD:

            let list = state.paymentCardList;

            list.push(action.payload);

            return {
                ...state,
                paymentCardList: list,
            }

        case DELETE_PAYMENTCARD:

            state.paymentCardList.map(paymentCard => {

                if (paymentCard.id === action.payload.id) {

                    state.paymentCardList.delete(paymentCard)
                    
                    return 
                }

            })

        case RESET_PAYMENTCARD:

            return {
                ...INITIAL_STATE,
            }

        default:
            return state;
    }
}
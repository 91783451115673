import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import GreatMask from '../../App/utils/GreatMask';
import FormikSelect from '../../App/components/FormikSelect';
import MUIButton from '../../App/components/MUIButton';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import { updateTicket } from '../ticketActions';
import { list } from '../../Vehicle/VehicleActions';
import './styles.css';
import Toast from '../../App/utils/Toast';
import {MenuItem, Divider, Card } from '@material-ui/core';

export default function FormUpdateTicketDialog({ open, setOpen, ticket, eventDateValue }) {

    const { resultSet, where } = useSelector((state) => state.vehicle);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [selectedEventDate, setSelectedEventDate] = useState(ticket.eventDateValue);
    const [selectedEventDateValue, setSelectedEventDateValue] = useState();
    const [openDialogConfirmBuy, setOpenDialogConfirmBuy] = useState(false);
    const [newEventDateValue, setNewEventDateValue] = useState('0,00');
    const [newVehicle, setNewVehicle] = useState();
    const [saveOk, setSaveOk] = useState(false);

    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    // console.log(ticket)

    const formik = useFormik({

        initialValues: {

            id:                 ticket?.id || 0,
            event_date:         ticket.eventDateValue?.eventDate?.id || null,
            vehicle:            ticket.ticketVehicle.id,
        },
        
        validate: values => {

            const errors = {};

            
            return errors;
        },

        onSubmit: values => {
            
            handleSubmit()
        },
    });

    useEffect(() => {

    }, [ticket])

    function handleListVehicles() {

        Toast.loading('Aguarde...');

        setLoading(true);

         dispatch(list({ offset: where.offset, limit: where.limit }, (response) => {

        }));

         Toast.dismiss();
        
        setLoading(false);
    }

    function handleSubmit() {

        setLoading(true);

        Toast.loading('Aguarde...');

        dispatch(updateTicket({

                ticket_id: ticket.id,
                vehicle:   newVehicle.id

            }, (resp) => {

            Toast.showToast(resp, 'Alterado com sucesso!')

            if (typeof resp === 'boolean' && resp) {

                handleClose();
            }
            else
                Toast.showToast('Não foi possível alterar. Causa: '+resp, '')

            setLoading(false);
        }));
    }

    function handleClose() {

        if (typeof setOpen === 'function') setOpen();
    }

    function handleSetEventDate (value) {

        setSelectedEventDate(value)

        // console.log(value)

        formik.setFieldValue('event_date', value);
    }

    function handleSetTicketValue (value) {

        // formik.setFieldValue('vehicle', value);

        setSaveOk(true);

        const vehicle = resultSet.filter((item) => (item.id === value) )
        
        if (vehicle) {

            // console.log(vehicle[0])

            setNewVehicle(vehicle[0])

            // verifica se o valor do veiculo selecionado para alterar eh o mesmo valor que ja foi pago
            // nao podendo alterar para um valor diferente
            const eventValue = eventDateValue.map( (eventValue) => {

                if (eventValue.vehicle_type === vehicle[0].vehicle_type.id) {

                    setSelectedEventDateValue(eventValue)

                    if (eventValue.value_online !== ticket.eventDateValue.value_online)
                        Toast.error('Valor pago diverge do valor para esse tipo de veículo.')
                    else
                        setSaveOk(true);
                }
            })
        }
    }

    return (

        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
        >
            <Typography variant="h5" className="dialog-title-left"> Alterar Veículo</Typography>

            <DialogContent className="dialog-content"
                style={{padding: 5, margin: 1, border: "0px solid black",  }}
            >

                 <Card className="card-content"
                    style={{padding: 5, margin: 1, border: "0px solid black",  }}
                 >
 
                    <div className="div-column">

                        <FormikSelect

                            label="Data Evento"
                            name="event_date"
                            value={formik.values.event_date}

                            onChange={(e) => {

                                handleSetEventDate(e.target.value)
                                
                            }}

                            className="dialog-input"
                            required
                            disabled
                        >
                            {
                                <MenuItem value={ticket.eventDateValue.eventDate.id} key={ticket.eventDateValue.eventDate.id} className="menu">

                                    <div className="menu-itens">

                                        {`${ticket.eventDateValue.eventDate.date}`}
                                    </div>

                                </MenuItem>
                            }
                        </FormikSelect>


                        <FormikSelect

                            label="Veículo Atual"
                            name="vehicle"
                            value={formik.values.vehicle}
                            disabled
                            className="dialog-input"
                        >
                            {resultSet.map((item) => <MenuItem value={item.id} key={item.id} className="menu">

                                <div className="menu-itens">

                                    {`${item.name}`}
                                </div>

                            </MenuItem>
                            )}
                        </FormikSelect>


                        <div className="div-column">

                            <Typography > Tipo Veículo: {ticket.ticketVehicle?.vehicle_type?.name}</Typography>
                        </div>
                        
                        <Divider />

                        <div style={{display: "flex", flexDirection: "row", alignItens: "right",  justifyContent: "right"}}>

                            R$ <span className="ticket-value"> {GreatMask.coinBR(ticket.eventDateValue.value_online)} </span>
                        </div>

                    </div>

                    <div className="div-row">
                        <Divider orientation="vertical"/>
                    </div>

                    <div className="div-column">

                        <FormikSelect

                            label="Trocar por"
                            name="new_vehicle"
                            value={formik.values.new_vehicle}

                            onChange={(e) => {

                                handleSetTicketValue(e.target.value)

                            }}

                            className="dialog-input"
                            required
                        >
                            {resultSet.map((item) => <MenuItem value={item.id} key={item.id} className="menu">

                                <div className="menu-itens">

                                    {`${item.name}`}
                                </div>

                            </MenuItem>
                            )}
                        </FormikSelect>

                        <div>
                            <Typography > Tipo Veículo: {newVehicle?.vehicle_type?.name}</Typography>
                        </div>
                        
                        <Divider />

                        <div style={{display: "flex", flexDirection: "row", alignItens: "right",  justifyContent: "right"}}>
                            R$ <span className="ticket-value"> {GreatMask.coinBR( selectedEventDateValue ? selectedEventDateValue?.value_online : 0)}</span>
                        </div>
                    </div>
                </Card>
            </DialogContent>

            <div className="button-content">

                <MUIButton onClick={handleClose} buttonType="red">
                    Cancelar
                </MUIButton>

                <MUIButton

                    buttonType="green"
                    disabled={saveOk ? false : true}
                    onClick={handleSubmit}
                >
                    Alterar
                </MUIButton>
            </div>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {loading && <Loader loading={loading} />}
        </Dialog>
    );
}
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import TextInput from '../../App/components/TextInput';
import TextInputPassword from '../../App/components/TextInputPassword';
import Loader from "../../App/components/Loader";
import AlertModalDialog from '../../App/components/AlertModalDialog';
import GreatMask from '../../App/utils/GreatMask';
import { changePassword, recoverPassword } from '../../MyAccount/userActions';
import './styles.css';
import Toast from '../../App/utils/Toast';

export default function RecoverPassword({ onToLogin }) {

    const dispatch = useDispatch();

    const [form, setForm] = useState({
        code: '',
        password: '',
        confirmPassword: ''
    });
    
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {

        setStep('USERNAME');
        setLoading(false);

    }, []);

    useEffect(() => {

        if (username.trim() === '')
            return setButtonDisabled(true);

        setButtonDisabled(false);

    }, [username])

    useEffect(() => {

        if (form.code.trim() === '' || form.password.length < 5 || form.password !== form.confirmPassword) {

            return setButtonDisabled(true);
        }

        setButtonDisabled(false);

    }, [form])

    function goToLogin() {

        if (typeof onToLogin === 'function' && !modal.open)
            onToLogin();
    }

    function handleChangePassword(e) {
        
        e.preventDefault();

        setLoading(true);

        Toast.loading('Aguarde...');

        dispatch(changePassword({

            password: form.password,
            code: form.code

        }, (resp) => {

            setLoading(false);
            
            if (typeof resp !== 'boolean' || !resp) {

                Toast.showToast(resp, '')

                return

                /*return setModal({
                    open: true,
                    title: 'Aviso',
                    text: resp
                })*/
            }

            Toast.showToast(resp, 'Senha Alterada com sucesso');

            /*setModal({
                open: true,
                title: 'Sucesso!',
                text: 'Senha alterado com sucesso!'
            })*/

            setStep('USERNAME');

            goToLogin();
        }));
    }

    function handleSubmitUsername(e) {

        e.preventDefault();

        setLoading(true);

        dispatch(recoverPassword(username, (resp) => {

            setLoading(false);

            if (typeof resp !== 'boolean' || !resp) {
                
                return setModal({
                    open: true,
                    title: 'Aviso',
                    text: resp
                })
            }

            setStep('PASSWORD');
        }));
    }

    function getComponent(ENUM) {

        switch (ENUM) {

            case 'USERNAME': return (

                <div id="recover-password-username-container">

                    <Typography variant="h4">Recuperar a senha</Typography>

                    <p>Insira o e-mail cadastrado para podermos enviar o código de segurança e recuperar a senha!</p>

                    <FormControl required className="input">
                        <TextField
                            id="standard-required-username"
                            label="E-mail"
                            value={username}
                            onChange={(e) => { setUsername(e.target.value) }}
                            className="textField"
                            variant="outlined"
                        />
                    </FormControl>

                    <button
                        className={clsx("button", "button-recover", !buttonDisabled ? "button-blue" : "button-gray")}
                        onClick={handleSubmitUsername}
                        disabled={buttonDisabled}
                    >
                        SOLICITAR ALTERAÇÃO DE SENHA
                    </button>

                    <button
                        className="button button-recover button-blue"
                        onClick={() => goToLogin()}
                    >
                        VOLTAR
                    </button>
                </div>
            );

            case 'PASSWORD': return (

                <div id="recover-password-username-container">

                    <Typography variant="h4">Recuperar a senha</Typography>

                    <p>{`Insira o código que enviamos para o ${username} e digite uma nova senha!`}</p>

                    <form className="form-content" onSubmit={handleChangePassword}>

                        <TextInput
                            label="Código de segurança"
                            value={form.code}
                            onChange={(e) => {
                                if (GreatMask.onlyNumber(e.target.value).length < 7)
                                    setForm({ ...form, code: GreatMask.onlyNumber(e.target.value) })
                            }}
                            className='input'
                            required
                            error
                        />

                        <TextInputPassword
                            label="Senha *"
                            value={form.password}
                            onChange={(e) => { setForm({ ...form, password: e.target.value }) }}
                            validValue='minNumberText'
                            minLength={6}
                            className='input'
                            error
                            required
                        />

                        <TextInputPassword
                            label="Confirme a senha *"
                            value={form.confirmPassword}
                            onChange={(e) => { setForm({ ...form, confirmPassword: e.target.value }) }}
                            validValue='minNumberText'
                            minLength={6}
                            className='input'
                            error
                            required
                        />

                        <button
                            className={clsx("button", !buttonDisabled ? "button-blue" : "button-gray")}
                            disabled={buttonDisabled}
                            type='submit'
                        >
                            ALTERAR SENHA
                        </button>

                        <button
                            className="button button-blue"
                            onClick={() => setStep('USERNAME')}
                            type='button'
                        >
                            VOLTAR
                        </button>
                    </form>
                </div>
            );

            default: return (
                <Loader loading={loading} />
            )
        }
    }

    return (
        <>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {!loading ? getComponent(step) : (<Loader loading={loading} />)}
        </>
    )
}
import { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Loader from "../../App/components/Loader";
import GreatMask from '../../App/utils/GreatMask';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import FormikTextInput from '../../App/components/FormikTextInput';
import MUIButton from '../../App/components/MUIButton';
import { createUserAddress } from '../userActions';
import './styles.css';
import Toast from '../../App/utils/Toast';

export default function MyAddressTabPanel({ userId, addressLength }) {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const formik = useFormik({

        initialValues: {
            zipcode: '',
            uf: '',
            city: '',
            district: '',
            street: '',
            number: '',
        },
        validate: values => {

            const errors = {};
            
            if (values.zipcode.length < 5) {
                errors.zipcode = true;
            }

            if (!values.street) {
                errors.street = true;
            }
            
            if (!values.number) {
                errors.number = true;
            }
            return errors;
        },
        onSubmit: values => {

            setLoading(true);

            Toast.loading('Aguarde...');
            
            dispatch(createUserAddress({

                zip_code: values.zipcode,
                number: values.number,
                id: String(userId)

            }, (resp) => {

                if (typeof resp !== 'boolean' || !resp) {

                    Toast.showToast(resp, '')

                    return
                }
                else {

                    Toast.showToast(resp, 'Cadastro efetuado com sucesso!')

                    formik.resetForm();
                    setShowForm(false);
                }
            }));

            setLoading(false);
        },
    });

    const searchZipcode = async (zipcode) => {
        
        setLoading(true);

        Toast.loading('Aguarde...');
        
        const json = await fetch(`https://viacep.com.br/ws/${zipcode}/json`).then(resp => resp.json());
        
        if (json.hasOwnProperty('erro')) {

            setLoading(false);
            
            /*return setModal({
                open: true,
                title: 'ERRO!',
                text: 'CEP não existe, informe o CEP corretamente.'
            })*/

            Toast.showToast('Endereço não encontrado.','');
        }
        
        Toast.showToast(true, 'Endereço encontrado.');

        formik.setFieldValue('district', json.bairro)
        formik.setFieldValue('city', json.localidade)
        formik.setFieldValue('street', json.logradouro)
        formik.setFieldValue('uf', json.uf)

        setLoading(false);
    }


    function getComponent(showForm) {

        if (!showForm) {

            return (
                <div className="without-data">
                    <Typography variant="subtitle1" className={!addressLength ? 'with-data' : ''}>
                        {addressLength
                            ? 'Oops! Você não cadastrou nenhum endereço até agora.'
                            : 'Quer cadastrar mais um endereço?'}
                    </Typography>

                    <MUIButton
                        buttonType="green"
                        onClick={() => { setShowForm(!showForm) }}
                    >
                        Cadastrar endereço
                    </MUIButton>
                </div>
            )
        }

        return (
            <form className="form-content" onSubmit={formik.handleSubmit}>

                <Typography variant="subtitle1" className="textField">
                    Informe os dados do endereço para cadastrar.
                </Typography>
            
                <FormikTextInput
                    label="CEP"
                    name="zipcode"
                    value={formik.values.zipcode}
                    onChange={(e) => {
                        const zipcodeNumber = GreatMask.onlyNumber(e.target.value)
                        formik.setFieldValue('zipcode', zipcodeNumber)
                        zipcodeNumber.length === 8 && searchZipcode(zipcodeNumber)
                    }}
                    InputLabelProps={{ maxLength: 8 }}
                    className="textField"
                    required
                    error={formik.errors.zipcode}
                />
                <FormikTextInput
                    label="Estado"
                    name="uf"
                    value={formik.values.uf}
                    onChange={formik.handleChange}
                    InputLabelProps={{ maxLength: 2 }}
                    className="textField"
                />
                <FormikTextInput
                    label="Cidade"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    className="textField"
                />
                <FormikTextInput
                    label="Bairro"
                    name="district"
                    value={formik.values.district}
                    onChange={formik.handleChange}
                    className="textField"
                />
                <FormikTextInput
                    label="Rua"
                    name="street"
                    value={formik.values.street}
                    onChange={formik.handleChange}
                    className="textField"
                />
                <FormikTextInput
                    label="Número"
                    name="number"
                    value={formik.values.number}
                    onChange={formik.handleChange}
                    className="textField"
                    required
                    error={formik.errors.number}
                />
                <MUIButton
                    type="submit"
                    buttonType="green"
                    disabled={formik.isValid === true && formik.dirty === true ? false : true}
                >
                    Cadastrar
                </MUIButton>

                <AlertModalDialog
                    open={modal}
                    setOpen={() => setModal({ ...modal, open: false })}
                />
            </form>
        )
    }

    return !loading ? getComponent(showForm) : (<Loader loading={loading} />)
}
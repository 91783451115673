import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import { useColors } from '../../App/hooks/useColors';
import { vehicleTypes, getIcon } from '../../App/utils/typesVehicles';
import GreatMask from '../../App/utils/GreatMask';
import Validation from '../../App/utils/Validation';
import FormikSelect from '../../App/components/FormikSelect';
import FormikTextInput from '../../App/components/FormikTextInput';
import MUIButton from '../../App/components/MUIButton';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import { newTicket, updateTicket } from '../eventActions';
import { list } from '../../Vehicle/VehicleActions';
import './styles.css';
import Toast from '../../App/utils/Toast';
import {MenuItem, Divider, Grid, Item, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmBuyTicket from './ConfirmBuyTicket';
import { useHistory } from 'react-router-dom'

export default function FormNewTicketDialog({ open, setOpen, eventDate }) {

    const { resultSet, affectedRows, where } = useSelector((state) => state.vehicle);
    const dispatch = useDispatch();
    const colors = useColors();
    const [loading, setLoading] = useState(false);
    const [editVehicle, setEditVehicle] = useState(false);
    const [selectedEventDate, setSelectedEventDate] = useState();
    const [selectedEventDateValue, setSelectedEventDateValue] = useState();
    const [openDialogConfirmBuy, setOpenDialogConfirmBuy] = useState(false);
    const history = useHistory();
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    // event =  event[0][0]

    // console.log(eventDate)

    const formik = useFormik({

        initialValues: {

            id:                 eventDate.event?.id || 0,
            event_date:         eventDate?.id || null,
            vehicle:            '',
        },
        
        validate: values => {

            const errors = {};
            
            return errors;
        },

        onSubmit: values => {
            
            // setLoading(true);
        },
    });


    useEffect(() => {

        if (eventDate?.event?.id) {

            setEditVehicle(true);

            handleListVehicles();
        }
    }, [eventDate.event])

    function handleListVehicles() {

        // Toast.loading('Buscando Veículo...');

        setLoading(true);

         dispatch(list({ offset: where.offset, limit: where.limit }, (response) => {

            if (resultSet.length == 1) {

                // handleSetTicketValue(resultSet[0], true)
            }
        }));

        Toast.dismiss();
        
        setLoading(false);
    }

    function handleSubmit(confirm) {

        if (confirm) {

            Toast.loading('Aguarde...');

            setLoading(true);

            dispatch(newTicket({

                    vehicle:            formik.values.vehicle.id ,
                    event_date_value:   selectedEventDateValue.id

                }, (resp) => {

                Toast.showToast(resp, 'Compra efetuada com sucesso!')

                if (typeof resp === 'boolean' && resp) {

                    handleClose();
                }
                else
                    Toast.showToast('Não foi possível cadastrar.', '')

                setLoading(false);

                setTimeout(function() {

                    history.push("/ticket");

                }, 2000)

            }));
        }

        setOpenDialogConfirmBuy(false)
    }


    function handleCreate(values) {

        dispatch(newTicket(values, (resp) => {

            setModal({

                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
            })

            if (typeof resp === 'boolean' && resp) {
                handleClose();
            }
            
            setLoading(false);
        }));
    }

    function handleChange(values) {

        dispatch(updateTicket({

            id: String(values.id),
            name: values.name
        }, 
        (resp) => {

            setModal({

                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
            })

            if (typeof resp === 'boolean' && resp) {
                handleClose();
            }

            setLoading(false);
        }));
    }

    function handleClose() {

        formik.resetForm();

        if (typeof setOpen === 'function') setOpen();
    }

    function handleSetEventDate (value) {

        setSelectedEventDate(value)

        formik.setFieldValue('event_date', value);
    }

    
    function handleSetTicketValue (vehicle) {

      /*  if (!selectedEventDate) {

            Toast.error('Selecione a data do evento.');

            return;
        }*/

        // console.log(vehicle)

        formik.setFieldValue('vehicle', vehicle);

        let foundEventValue = false
        
        const eventValue = eventDate.eventDateValue.map( (eventValue) => {

            if (eventValue.vehicleType.id === Number(vehicle.vehicle_type.id)) {

                setSelectedEventDateValue(eventValue)

                foundEventValue = true

                return eventValue
            }
        })
        
        // console.log(foundEventValue)

        if (!foundEventValue) {

            Toast.error("Sem vagas disponiveis para tipo de veículo '"+vehicle.vehicle_type.name+"'")
        }
    }

    function handleConfirmBuy() {

        setOpenDialogConfirmBuy(true)
    }

    const useStyles = makeStyles((theme) => ({
      
      container: {
        border: '1px solid green',
        display: 'inline-flex',        
      },

      dialogContent1: {
        border: '1px solid green',
        padding: '0px',
        margin: '0px',
        background: "#eeece4 ",
      },
      
      paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    }));

     const classes = useStyles();

    return (

        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
        >
            <Typography variant="h5" className="dialog-title-left"> Novo Ticket</Typography>

            {openDialogConfirmBuy && (
                <ConfirmBuyTicket open={openDialogConfirmBuy} onConfirm={handleSubmit(true, selectedEventDateValue)} />
            )}
            
            <DialogContent className="dialog-content"
                style={{padding: 0, margin: 0, border: "0px solid black",  }} 
            >

                 <Card className="card-content"
                    style={{padding: 5, margin: 5, border: "0px solid black",  }} 
                 >
                    
                    <div className="content-form">

                         <div className="div-column">
                            <Typography variant="h4"> {eventDate.event.name} </Typography>
                            <Typography variant="h5"> {eventDate.description} </Typography>
                            <Typography variant="h6"> {eventDate.date} </Typography>
                        </div>

                        <Divider/>

                        
                        <div className="div-column">

                            {/*<FormikSelect
                                label="Data Evento"
                                name="event_date"
                                value={formik.values.event_date}

                                onChange={(e) => {

                                    handleSetEventDate(e.target.value)
                                    
                                }}

                                className="dialog-input"
                                required
                            >
                                {event.eventDate.map((item) => <MenuItem value={item} key={item.id} className="menu">

                                    <div className="menu-itens">

                                        {`${item.date}`}
                                    </div>

                                </MenuItem>
                                )}
                            </FormikSelect>*/}


                            <FormikSelect

                                label="Veículo"
                                name="vehicle"
                                value={formik.values.vehicle}

                                onChange={(e) => {

                                    handleSetTicketValue(e.target.value, false)
                                }}

                                className="dialog-input"
                                required
                            >
                                {resultSet.map((item) =>

                                     <MenuItem value={item} key={item.id} className="menu">

                                        {item.name}

                                    </MenuItem>
                                )}
                            </FormikSelect>


                            <Typography > Tipo Veículo: {selectedEventDateValue?.vehicleType.name}</Typography>

                        </div>

                        <Divider />

                        <div className="div-column">

                            {selectedEventDateValue?.vacancy_qty === 0 && (
                                <Typography style={{color: colors.redCS}}> Esgotado </Typography>
                            )}

                            {selectedEventDateValue?.vacancy_qty > 0 && (

                                <div flexDirection="row">
                                    R$ <span className="ticket-value">{GreatMask.coinBR(selectedEventDateValue.value_online)}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </Card>
            </DialogContent>
            

            <div className="button-content">

                <MUIButton onClick={handleClose} buttonType="red">
                    Cancelar
                </MUIButton>

                <MUIButton
                    buttonType="green"
                    disabled={formik.isValid === true && formik.dirty === true && selectedEventDateValue?.vacancy_qty > 0 ? false : true}
                    onClick={handleConfirmBuy}
                >
                    Comprar
                </MUIButton>
            </div>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {loading && <Loader loading={loading} />}
        </Dialog>
    );
}
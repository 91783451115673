import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Loader from '../App/components/Loader';
import MainWithData from '../App/components/MainWithData';
import MainWithoutData from '../App/components/MainWithoutData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import MUIButton from '../App/components/MUIButton';
import { logout } from '../Login/LoginActions';
import PaymentCardTable from './components/PaymentCardTable';
import ConfirmDeleteDialog from './components/ConfirmDeleteDialog';
import { list, deletePaymentCard } from './paymentCardActions.js';
import './styles.css';
import Toast from '../App/utils/Toast';
import { ToastContainer } from 'react-toastify';

export default function PaymentCard() {

    const { paymentCardList, affectedRows, where } = useSelector((state) => state.paymentCard);
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [checkedAtivo, setCheckedAtivo] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [selectPaymentCard, setSelectPaymentCard] = useState({});
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    // console.log(resultSet)
    
    useEffect(() => {

        // console.log('oi')

        /*if (affectedRows === 1) {

            dispatch(list({ offset: where.offset, limit: where.limit }, (response) => {

                if (!response) {

                    dispatch(logout(() => {
                        history.push('/')
                    }))
                }
            }));

            setLoading(false);
        }*/

        setLoading(false);

    }, [affectedRows, history, where, dispatch]);

    function handleList() {

        Toast.loading('Aguarde...');

        setLoading(true);

        dispatch(list({ offset: where.offset, limit: where.limit }, (resp) => {

            setLoading(false);

            if (typeof resp === 'string') {

                Toast.showToast(resp, '')
            }

            if (typeof resp === 'boolean' && !resp) {

                Toast.showToast(resp, '')
            }

            Toast.dismiss();
        }));
    }


    function handleDialogDelete(card) {

        setSelectPaymentCard(card);

        setOpenDialogDelete(true);
    }

    function handleConfirmDelete(confirm) {

        if (confirm) {
            
            Toast.loading('Aguarde...');

            setLoading(true);

             dispatch(deletePaymentCard(selectPaymentCard , (response) => {

                if (response)
                    Toast.showToast(true, 'Excluído com sucesso!')
                else
                    Toast.showToast('Não foi possível excluir.', '');
                
                setLoading(false);
            }));
        
        }
        else {

            setSelectPaymentCard({});

            setLoading(false);
        }

        setOpenDialogDelete(false)
    }

    function verifyResponse(response) {

        if (response) {

            setSelectPaymentCard({});

            /*setModal({

                open: true,
                title: 'Sucesso!',
                text: 'Alteração com sucesso!'
            })*/

            Toast.showToast(response, 'Alterado com sucesso!');
        }
        else 
            Toast.showToast('Nao foi possvel alterar os dados.', '')

        setLoading(false);
    }

    function getTableData() {

        // let vehicleActive = resultSet.filter((item) => item.active === checkedAtivo)

        if (paymentCardList.length === 0)
            return (
                <MainWithoutData
                    title="Lista de Cartões"
                    text="Não há cartões cadastrados."
                />
            )

        return (

            <MainWithData title="Lista de Cartões">
                <PaymentCardTable                    
                    resultSet={paymentCardList}
                    onDelete={handleDialogDelete}
                    onCancel={() => { setSelectPaymentCard({}) }}
                />

            </MainWithData>
        )
    }

    return (

        <Grid container>

            <ToastContainer />

            {openDialogDelete && (
                <ConfirmDeleteDialog open={openDialogDelete} onConfirm={handleConfirmDelete} card={selectPaymentCard} />
            )}

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            <Grid item xs={12}>

                <Card id="header-container">

                    <div className="header-content">
                        <div className="half-div">
                            <Typography variant="h4">Meus Cartões</Typography> 

                             <MUIButton
                                color="primary"
                                onClick={handleList}
                                >
                                Listar
                            </MUIButton>
                        </div>

                    </div>
                </Card>
            </Grid>

            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>
        </Grid>
    );
}
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import MyDataTabPanel from './MyDataTabPanel';
import MyAddressTabPanel from './MyAddressTabPanel';
import ChangePasswordTabPanel from './ChangePasswordTabPanel';
import ExistAddress from './ExistAddress';
import { createEmailCellphone, deleteCellphone, deleteEmail, deleteUserAddress, deleteAccount } from '../userActions';
import Loader from "../../App/components/Loader";
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Toast from '../../App/utils/Toast';
import ConfirmDeleteAccountDialog from './ConfirmDeleteAccountDialog';

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <Card
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (<Box p={3}>{children}</Box>)}
        </Card>
    );
}

function a11yProps(index) {

    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function MyAccountTab() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { user, address } = useSelector((state) => state.user);
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const [openDialogDeleteAccount, setOpenDialogDeleteAccount] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {

        if (!user?.id) 
            return history.push('/');

        setLoading(false);

    }, [user, address, history]);

    const handleSaveEmailCellphoneData = async (newData) => {

        setLoading(true);

        dispatch(createEmailCellphone({
            email: newData.email2,
            phone_number: newData.cellphone2,
        }, ((resp) => {
            if (resp) {
                return changeTextModal('Sucesso', 'Cadastro com sucesso');
            }

            changeTextModal('ERRO', 'Erro ao cadastrar');
        })))
        setLoading(false);
    }

    const handleDeleteEmail = (item) => {

        setLoading(true);

        dispatch(deleteEmail({
            main: item.main,
            email: item.email
        }, ((resp) => {

            if (resp) {
                return changeTextModal('Sucesso', 'Alteração com sucesso');
            }

            changeTextModal('ERRO', 'Erro ao deletar');
        })))

        setLoading(false);
    }
    const handleDeleteCellphone = (item) => {

        setLoading(true);

        dispatch(deleteCellphone({
            main: item.main,
            phone_number: item.phone_number
        }, ((resp) => {
            if (resp) {
                return changeTextModal('Sucesso', 'Alteração com sucesso');
            }

            changeTextModal('ERRO', 'Erro ao deletar');
        })))
        setLoading(false);
    }

    const handleDeleteAddress = (item) => {

        setLoading(true);

        Toast.loading('Aguarde...');

        dispatch(deleteUserAddress(item.id, ((resp) => {

            if (typeof resp !== 'boolean' || !resp) {

                Toast.showToast('Não foi possível excluir. ', '')

                return
            }
            else {

                Toast.showToast(true, 'Excluído com sucesso!')
            }
        })))

        setLoading(false);
    }

    function handleDeleteAccount() {


        setOpenDialogDeleteAccount(true);
    }

    const handleConfirmDeleteAccount = (confirm) => {

        if (confirm) {

            setLoading(true);

            Toast.loading('Aguarde...');

            dispatch(deleteAccount(((resp) => {

                if (typeof resp !== 'boolean' || !resp) {

                    Toast.showToast('Não foi possível excluir a conta. ', '')

                    return
                }
                else {

                    Toast.showToast(true, 'Conta excluída com sucesso!')

                    setTimeout(function() {

                        history.push("/login");

                    }, 2000)
                }
            })))

            setLoading(false);
        }
        else
            setOpenDialogDeleteAccount(false)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function changeTextModal(title, text) {
        setModal({
            open: true,
            title,
            text,
        })
    }

    return (
        <div id="my-account-tab-container">
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />

            {openDialogDeleteAccount && (
                <ConfirmDeleteAccountDialog open={openDialogDeleteAccount} onConfirm={handleConfirmDeleteAccount} />
            )}

            <Paper id="mydata-options">
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                >
                    <Tab label="Meus dados" {...a11yProps(0)} />
                    <Tab label="Meus Endereços" {...a11yProps(1)} />
                    <Tab label="Alterar senha" {...a11yProps(2)} />
                </Tabs>
            </Paper>
            {!loading ? (
                <>
                    <TabPanel value={value} index={0} className="mydata-tab-panel">
                        <MyDataTabPanel
                            user={user}
                            onSaveEmailCellphone={handleSaveEmailCellphoneData}
                            onDeleteEmail={handleDeleteEmail}
                            onDeleteCellphone={handleDeleteCellphone}
                            onDeleteAccount={handleDeleteAccount}
                        />
                    </TabPanel>

                    <TabPanel value={value} index={1} className="mydata-tab-panel">

                        {address.length > 0 && address.map((item, index) => (
                            <ExistAddress
                                key={item.id}
                                indexNumber={index}
                                address={item}
                            />
                        ))}

                        {address.length < 2 && (
                            <MyAddressTabPanel
                                userId={user.id}
                                addressLength={address.length > 0 ? false : true}
                            />
                        )}
                    </TabPanel>

                    <TabPanel value={value} index={2} className="mydata-tab-panel">
                        <ChangePasswordTabPanel username={user?.email} />
                    </TabPanel>
                </>
            ) : (
                <Loader loading={loading} />
            )}
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import AlertModalDialog from '../App/components/AlertModalDialog';
import FormLogin from './components/FormLogin';
// import FormCreateAccount from './components/FormCreateAccount';
import CreateAccountDialog from './components/CreateAccountDialog';
import RecoverPassword from './components/RecoverPassword';
import Loader from '../App/components/Loader';
import { ENUM_LOGIN } from './enum';
import { login } from './LoginActions';
import LoginImage from '../App/assets/loginImage.jpg';
import './styles.css';
import Toast from '../App/utils/Toast';
import { ToastContainer } from 'react-toastify';
import { useParams } from "react-router-dom";

export default function Login() {
    
    const dispatch = useDispatch();
    const history = useHistory();

    const [step, setStep] = useState(ENUM_LOGIN.LOGIN);
    const [loading, setLoading] = useState(true);
    const [newAccount, setNewAccount] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        setLoading(false);
    }, [])

    
    // pega o id do evento como parametro, passado atraves de um link
    // isso para direcionar direto para o evento apos o login, encurtando caminho
    let { event_date_id } = useParams();
    event_date_id = event_date_id ? event_date_id : '';

    function handleToSigin(value) {

        setLoading(true);

        dispatch(login(value.username, value.password, (resp) => {

            // if (typeof resp === 'boolean' && resp) {
                // return history.push('/event');
            // }

            setModal({
                open: true,
                title: 'Aviso!',
                text: resp
            });
            setLoading(false);
        }));
    }

    function handleToGoCreateAccount() {

        // setStep(ENUM_LOGIN.CREATE_ACCOUNT);
        setNewAccount(true)
    }

    function handleToGoRecoverPassword() {
        
        setStep(ENUM_LOGIN.RECOVER_PASSWORD);
    }

    function handleToLogin(email, password) {

        setStep(ENUM_LOGIN.LOGIN);

        if (email && password) {

            setLoading(true);

            Toast.loading('Aguarde...');

            dispatch(login(email, password, (resp) => {

                if (typeof resp === 'boolean' && resp) {

                    Toast.dismiss();

                    return history.push('/event/'+event_date_id);
                }

                Toast.showToast(resp, '')
    
                setLoading(false);
            }));
        }
    }

    function getComponent() {

        switch (step) {

            case ENUM_LOGIN.LOGIN: return (
                <FormLogin
                    event_date_id={event_date_id}
                    onSigin={handleToSigin}
                    onRecoverPassword={handleToGoRecoverPassword}
                    onCreateAccount={handleToGoCreateAccount}
                />
            );
            // case ENUM_LOGIN.CREATE_ACCOUNT: return (
            //     <FormCreateAccount
            //         onToLogin={handleToLogin}
            //     />
            // );
            case ENUM_LOGIN.RECOVER_PASSWORD: return (
                <RecoverPassword
                    onToLogin={handleToLogin}
                />
            );
            default: return (
                <FormLogin
                    event_date_id={event_date_id}
                    onSigin={handleToSigin}
                    onCreateAccount={handleToGoCreateAccount}
                />
            );
        }
    }

    return (
        <Grid container>

            <ToastContainer />
            
            <Hidden smDown >
                <Grid item xs={false} sm={false} md={6} lg={8} xl={8} className="img-container" >
                    <img src={LoginImage} alt="Imagem de estacionamento" />
                </Grid>
            </Hidden>

            <Grid item sm={12} md={6} lg={4} xl={4} className="form-container">
                {!loading ? getComponent() : <Loader loading={loading} />}
            </Grid>

            <CreateAccountDialog
                open={newAccount}
                cancel={() => setNewAccount(false)}
                onNewAccountOk={handleToLogin}
            />

            {modal.open && (
                <AlertModalDialog
                    open={modal}
                    setOpen={() => setModal({ ...modal, open: false })}
                />
            )}
        </Grid>
    );
}
import { makeStyles } from "@material-ui/core/styles";
import { useColors } from "./useColors";

const drawerWidth = 218;

const useStyles = makeStyles((theme) => {

    const colors = useColors();

    return {

        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            background: colors.greenLight
        },
        appBarShift: {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
        drawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerClose: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.spacing(7) + 1,
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9) + 1,
            },
            [theme.breakpoints.down('sm')]: {
                width: theme.spacing(0) + 1,
            },
        },
        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        toolbarOpenDrawer: {
            background: colors.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
        },
        content: {
            flexGrow: 1,
            // padding: theme.spacing(3),
        },
        dialogContent: {
            display: 'flex',
            flexDirection: 'column',
        },
        openMyData: {
            display: 'flex',
            flexDirection: 'column',
            padding: 10,
            justifyContent: 'flex-end',
            background: colors.white
        },
        closeMyData: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 10,
            paddingBottom: 10
        },
        userText: {
            color: colors.grayDarkCS
        },

        table: {
            minWidth: 50,
        },

        tableHead: {
            minHeight: 1,
            height: 2,
            padding: 'dense'
        },
        tableRow: {
            height:5, 
            "&:nth-of-type(odd)": {
                backgroundColor: colors.grayLight
            }
        },
        tableCell: {
            padding: 0,
            paddingLeft: 10,
            marginTop: 0,
            marginBottom: 0
        }
    }
});

export default useStyles;
export const SET_PARKING = 'SET_PARKING';
export const RESET_PARKING = 'RESET_PARKING';

const INITIAL_STATE = {
    parkings: []
}

export function parkingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_PARKING:
            return {
                ...state,
                parkings: action.payload
            }
        case RESET_PARKING:
            return INITIAL_STATE
        default:
            return state
    }
}
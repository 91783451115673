import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Loader from '../App/components/Loader';
import MUIButton from '../App/components/MUIButton';
import MainWithData from '../App/components/MainWithData';
import MainWithoutData from '../App/components/MainWithoutData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import InputKeyboardDatePicker from '../App/components/InputKeyboardDatePicker';
import { filterEvent, setEvent, setCities } from './eventActions';
import EventTable from './components/EventTable';
import './styles.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Toast from '../App/utils/Toast';
import { ToastContainer } from 'react-toastify';
import { addMonths } from 'date-fns';
import FormNewTicketDialog from './components/FormNewTicketDialog'
import FormikTextInput from '../App/components/FormikTextInput';
import { useParams } from "react-router-dom";
import { list } from '../Vehicle/VehicleActions';
import { useHistory } from 'react-router-dom'
import { newTicket} from './eventActions';

export default function Event() {

    const { event, affectedRows } = useSelector((state) => state.event);
    const { resultSet, where } = useSelector((state) => state.vehicle);
    const { cities } = useSelector((state) => state.cities);
    const dispatch = useDispatch();
    const [initialDate, setInitialDate] = useState(() => {
        const today = new Date();
        const makeDate = new Date(today.setMonth(today.getMonth()));
        return makeDate;
    });

    const history = useHistory();

    const [startNewTicket, setNewTicket] = useState(false);

    const date = new Date();
    const newDate = addMonths(date, 1);

    const [finalDate, setFinalDate] = useState(newDate);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const [selectedCity, setSelectedCity] = useState('');
    const [selectedEventDate, setSelectedEventDate] = useState('')

    useEffect(() => {

        if (affectedRows === 0)
            dispatch(setEvent({ offset: 0, limit: 10 }, () => { }));

        setLoading(false)
    }, [dispatch, affectedRows])

    useEffect(() => {

        dispatch(setCities(() => { }));

        setLoading(false)
    }, [dispatch])

    let { event_date_id } = useParams();
    let searchByLink = false; // indica que o cliente escaneou QrCode ou clicou no link do evento

    // console.log(event_date_id)

    useEffect(() => {

        if (event_date_id) {

            searchByLink = true;

            handleFilterEvent(event_date_id);
        }
    }, [dispatch]);

    function handleFilterEvent() {

        // se nao for pesquisar pelo link direto via QrCode, entao pelo click no botao Filtrar
        if (!searchByLink)
            event_date_id = ''

        Toast.loading('Aguarde...');

        setLoading(true);

        dispatch(filterEvent(event_date_id, selectedCity, { initialDate, finalDate }, (resp) => {

            setLoading(false);

            if (typeof resp === 'string') {

                Toast.showToast(resp, '')
            }

            if (typeof resp === 'boolean' && !resp) {

                Toast.showToast(resp, '')
            }

            if (searchByLink && resp[0][0].eventDate.length == 1) {
                
                handleNewDirectTicket(resp[0][0].eventDate[0])
            }
            else
                Toast.dismiss();
        }));
    }

    function handleNewTicket (selectedEventDate) {

        setSelectedEventDate(selectedEventDate)

        setNewTicket(true)
    }

    function getTableData() {

        if (event.length === 0) {

            return (

                <MainWithoutData title="Lista dos eventos" />
            )
        }

        return (

            <MainWithData title="Lista dos eventos">
                <EventTable 
                resultSet={event} 
                newTicket={handleNewTicket}/>
            </MainWithData>
        )
    }


    // compra direta do ticket qndo o evento eh em unico dia e o usuario so tem 1 veiculo
    function handleNewDirectTicket(event) {

        // Toast.loading('Buscando Veículo...');

        // console.log(event.eventDateValue)

        event_date_id = ''

        setLoading(true);

         dispatch(list({ offset: where.offset, limit: where.limit }, (vehicle) => {

            if (vehicle.length == 1) {
                
                // Toast.showToast(true, 'Aguarde..')

                // console.log(vehicle)

                let foundEventValue = false

                const eventValue = event.eventDateValue.map( (eventValue) => {

                    if (eventValue.vehicleType.id === Number(vehicle[0].vehicle_type.id)) {

                        dispatch(newTicket({

                                vehicle:            vehicle[0].id,
                                event_date_value:   eventValue.id

                            }, (resp) => {

                            Toast.showToast(resp, 'Compra efetuada com sucesso!')

                            if (typeof resp === 'boolean' && resp) {

                                // handleClose();
                            }
                            else
                                Toast.showToast('Não foi efetuar a compra.', '')

                            setLoading(false);

                            setTimeout(function() {

                                history.push("/ticket");

                            }, 2000)

                        }));

                        foundEventValue = true

                        return eventValue
                    }
                })

                if (!foundEventValue) {

                    Toast.dismiss();

                    setLoading(false);

                    Toast.error("Não foi efetuar a compra. Sem vagas disponiveis para tipo de veículo '"+vehicle[0].vehicle_type.name+"'")
                }
            }
            else {

                Toast.dismiss();

                setLoading(false);
            }
        }));
    }


    return (
        <Grid container>

            <ToastContainer />

            {/* console.log(cities) */}

            {startNewTicket && (
                <FormNewTicketDialog
                    open={newTicket}
                    setOpen={() => setNewTicket(false)}
                    eventDate={selectedEventDate}
                />
            )}

            <Grid item xs={12} >

                <Card id="header-container" sx={{ overflow: "auto" }}>

                    <div className="title-content">
                        <Typography variant="h5">Eventos</Typography>
                    </div>
                    
                     <div className="history-filter-content1">

                        {/*<div className="div-column">

                            <InputKeyboardDatePicker
                                label="Data inicial"
                                value={initialDate}
                                onChange={(date) => setInitialDate(date)}
                                className="filter-itens"
                            />
                            &nbsp;
                            &nbsp;
                            <InputKeyboardDatePicker
                                label="Data final"
                                value={finalDate}
                                onChange={(date) => setFinalDate(date)}
                                className="filter-itens"
                                disableFuture={false}
                            />
                        </div> */}

                        <div className="div-column">

                            <Autocomplete
                                disablePortal
                                id="eventCity"
                                onChange={(event, value) => setSelectedCity(value.label) }
                                options={cities}
                                sx={{ minWidth: 200 }}
                                renderInput={(params) => <FormikTextInput {...params} label="Cidade" />}
                                className="filter-itens"
                            />

                            <MUIButton
                                color="primary"
                                onClick={handleFilterEvent}>

                                Filtrar

                            </MUIButton>
                        </div>
                    </div>
                </Card>
            </Grid>
            
            <Grid item xs={12} >
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Grid>
    );
}
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import GreatMask from '../../App/utils/GreatMask';
import { useColors } from '../../App/hooks/useColors';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function ParkingTable({ resultSet }) {
    const colors = useColors();
    const classes = useStyles();

    function getTodayOpeningHours(value) {
        const weekday = GreatMask.weekDay();

        return `${value[weekday].open} até ${value[weekday].close}`
    }

    function getTodayOpen(value) {
        const weekday = GreatMask.weekDay();

        return value[weekday].working ? 'Aberto' : 'Fechado'
    }

    function getTodayOpenColor(value) {
        const weekday = GreatMask.weekDay();

        return value[weekday].working ? colors.greenCS : colors.red
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell>Endereço</TableCell>
                        <TableCell>Horário</TableCell>
                        <TableCell>Aberto</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resultSet.map((parking) => (
                        <TableRow key={parking.id}>
                            <TableCell component="th" scope="row">{parking.name}</TableCell>
                            <TableCell>{parking.address}</TableCell>
                            <TableCell>{getTodayOpeningHours(parking.openingHours[0])}</TableCell>
                            <TableCell style={{ color: getTodayOpenColor(parking.openingHours[0]) }}>{getTodayOpen(parking.openingHours[0])}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from "../../App/hooks/useStyles";
import './styles.css';

export default function ConfirmVehicleDialog({ open, onConfirm, vehicle }) {
    const classes = useStyles();

    function handleConfirm() {
        if (typeof onConfirm === 'function') onConfirm(true);
    }

    function handleClose() {
        if (typeof onConfirm === 'function') onConfirm(false);
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Alterar o status do Veículo</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description" className={classes.dialogContent} >
                        <span>{`Deseja alterar o status para ${vehicle.active ? 'Inativo' : 'Ativo'} do veículo ${vehicle.name}?`}</span>
                    </DialogContentText>
                </DialogContent>

                <DialogActions>

                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>

                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Confirmar
                    </Button>
                    
                </DialogActions>
            </Dialog>
        </div>
    );
}
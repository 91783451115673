import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Settings from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from '@material-ui/core/Menu';
import { useColors } from '../../App/hooks/useColors';
import useStyles from "../../App/hooks/useStyles";
import './styles.css';

export default function PaymentCardTable({ resultSet, onDelete }) {

    const colors = useColors();
    const classes = useStyles();

    // console.log(resultSet)

    function handleDelete(paymentCard, close = () => { }) {

        if (typeof onDelete === 'function')
            onDelete(paymentCard);

        close()
    }

    function handleViewData (paymentCard) {

        // console.log(paymentCard)
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Número</TableCell>
                        <TableCell align="center">Bandeira</TableCell>
                        <TableCell align="right">Opções</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resultSet[0].map((paymentCard) => (

                        <TableRow key={paymentCard.id} className={classes.tableRow} onClick={ () => handleViewData(paymentCard)}>

                            <TableCell align="left" className={classes.tableCell}>**** **** **** {paymentCard.number}</TableCell>
                            <TableCell align="center" className={classes.tableCell}>{paymentCard.brand}</TableCell>
                            <TableCell align="right" className={classes.tableCell}>
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState) => (
                                        <>
                                            <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                                <Settings />
                                            </IconButton>

                                            <Menu {...bindMenu(popupState)}>

                                                <MenuItem onClick={() => handleDelete(paymentCard, popupState.close())}>
                                                    Excluir
                                                </MenuItem>

                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { EventVacancyStatus } from './EventVacancyStatus'
import MUIButton from '../../App/components/MUIButton';
import useStyles from "../../App/hooks/useStyles";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';

export default function EventTable({ resultSet, newTicket }) {
    
    const classes = useStyles();

    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const [vacancyQty, setVacancyQty] = useState(1)

    const [selectedEvent, setSelectedEvent] = useState();

    // console.log(resultSet[0])

    function handleNewTicket (eventDate) {

        // setModal({ ...modal, open: false })

        newTicket(eventDate)
    }

    function handleConfirm(event) {
        
        setSelectedEvent(event)

        setModal({ ...modal, open: true, title: event.name })
    }

    function cancel() {

        setModal({ ...modal, open: false })
    }

    return (

        <TableContainer component={Paper}>

            <Table className={classes.table} aria-label="simple table">
            
                <TableHead >
                    <TableRow>
                        <TableCell className="no-mobile-column">Nome</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell>Local</TableCell>
                        <TableCell>Cidade</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell className="no-mobile-column"></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {

                        resultSet[0].map((event, idx) => (

                            event.eventDate.map((eventDate, idx) => (
                                    
                                <TableRow key={eventDate.id} className={classes.tableRow} onClick={() => handleNewTicket(eventDate)}>
                                
                                    {/* console.log(resultSet[0][0].eventCity) */}
                                
                                    <TableCell component="th" scope="row" className="no-mobile-column">{event?.name}</TableCell>
                                    <TableCell component="th" scope="row">{eventDate?.description}</TableCell>
                                    <TableCell component="th" scope="row">{event?.local}</TableCell>
                                    <TableCell>{event.eventCity.name}</TableCell>
                                    <TableCell>{eventDate.date}</TableCell>

                                    <TableCell align="center" padding="none" className="no-mobile-column">
                                        
                                        {vacancyQty  === 0 
                                            ? <EventVacancyStatus qty={vacancyQty} />
                                            :
                                             <MUIButton
                                                color="primary"
                                                className="filter-itens"
                                                onClick={() => handleNewTicket(eventDate)}>

                                                Comprar

                                            </MUIButton>
                                        }

                                    </TableCell>

                                </TableRow>
                                
                            ))
                        ))
                    }
                </TableBody>
            </Table>

            <AlertModalDialog
                open={modal}
                handleNewTicket={handleNewTicket}
                cancel={cancel}
            />
        </TableContainer>
    );
}

function AlertModalDialog({ open, handleNewTicket, cancel }) {
    
    const classes = useStyles();

    return (
        <Dialog
            open={open.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            minWidth="md"
        >
            <DialogTitle>{open.title ? open.title : 'Aviso'}</DialogTitle>

            <DialogContent>
                <DialogContentText className={classes.dialogContent} >
                    <span>{open.text}</span>
                </DialogContentText>
            </DialogContent>
            
            <DialogActions>

                <MUIButton onClick={cancel} buttonType="red">
                    Cancelar
                </MUIButton>

                <MUIButton onClick={handleNewTicket} buttonType="green">
                    Comprar
                </MUIButton>
            </DialogActions>
        </Dialog>
    );
}
export const SET_TICKET = 'SET_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const UPDATE_TICKET_STATUS = 'UPDATE_TICKET_STATUS';
export const FILTER_TICKET = 'FILTER_TICKET';
export const RESET_TICKET = 'RESET_TICKET';

const INITIAL_STATE = {

    ticket: [],
    affectedRows: 1,
    where: {
        offset: 0,
        limit: 10,
    }
}

export function ticketReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case SET_TICKET:

            const newEvent = [...state.ticket, ...action.payload];
            const newOffset = action.payload.length;

            return {

                ...state,
                ticket: newEvent,
                affectedRows: newOffset < state.where.limit ? 0 : 1,
                where: {
                    ...state.where,
                    offset: state.where.offset + newOffset
                }
            }

        case FILTER_TICKET:

            const filter = [...state.ticket, ...action.payload];

            return {
                ...state,
                ticket: filter,
            }

        case UPDATE_TICKET:

            // console.log(action.payload[0])

            const alterData = state.ticket.map(ticket => {


                if (ticket.id === action.payload[0].id) {

                    ticket.ticketVehicle = action.payload[0].ticketVehicle;

                    return {
                        ...ticket,
                    }
                }
                
                return ticket
            })

            return {

                ...state,
                ticket: alterData,
            }

        case UPDATE_TICKET_STATUS:

            const ticketUpdateStatus = state.ticket.map(ticket => {

                if (ticket.id === action.payload.id) {
                
                    ticket.eventTicketStatus    = action.payload.eventTicketStatus;
                    ticket.paid                 = action.payload.paid;
                    ticket.payment_id           = action.payload.payment_id;

                    return {
                        ...ticket,
                    }
                }
                
                return ticket
            })

            return {

                ...state,
                ticket: ticketUpdateStatus,
            }

        
        case RESET_TICKET:

            return {
                ...INITIAL_STATE,
            }

        default:
            return state
    }
}
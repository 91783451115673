import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Validation from '../utils/Validation';
import GreatMask from '../utils/GreatMask';

export default function TextInput(props) {
    const {
        label,
        value,
        onChange,
        error = false,
        maxLength,
        required = false,
        disabled = false,
        errorInput = false,
        validValue = undefined,
        minLength = 0,
        className
    } = props;

    const [textError, setTextError] = useState(errorInput);

    function onChangeBlur(value) {
        if (typeof validValue === 'string') {
            return setTextError(getValid(value.target.value, validValue) ? false : true)
        }

        if (error)
            setTextError(value.target.value === '' ? true : false)
        else
            setTextError(false)
    }

    function getValid(value, type) {
        switch (type) {
            case 'birthday': return Validation.birthdayAdult(value);
            case 'cpf': return GreatMask.onlyNumber(value).length === 11;
            case 'cellphone': return GreatMask.onlyNumber(value).length === 11;
            case 'minNumberText': return typeof minLength === 'number' ? value.length > minLength : false;
            default: return false
        }
    }

    return (
        <TextField
            id={`standard-required-name-${label.trim()}`}
            label={label}
            value={value}
            onChange={(value) => { onChange(value) }}
            onBlur={(value) => { onChangeBlur(value) }}
            error={textError}
            className={className}
            variant="outlined"
            required={required}
            inputProps={{
                maxLength: maxLength
            }}
            disabled={disabled}
        />
    )
}
export const SET_LIST_VEHICLE = 'SET_LIST_VEHICLE';
export const ADD_LIST_VEHICLE = 'ADD_LIST_VEHICLE';
export const CHANGE_ACTIVE_VEHICLE = 'CHANGE_ACTIVE_VEHICLE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const RESET_VEHICLE = 'RESET_VEHICLE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';

const INITIAL_STATE = {

    resultSet: [],
    affectedRows: 1,
    where: {
        offset: 0,
        limit: 8,
    }
};

export function vehicleReducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case SET_LIST_VEHICLE:

            // const newVehicle = [...state.resultSet, ...action.payload];
            const newVehicle = [...action.payload];
            const newOffset = action.payload.length;

            return {

                ...state,
                resultSet: newVehicle,
                affectedRows: newOffset < state.where.limit ? 0 : 1,
                where: {
                    ...state.where,
                    offset: state.where.offset + newOffset
                }
            }

        case ADD_LIST_VEHICLE:

            let list = state.resultSet;

            list.push(action.payload);

            return {
                ...state,
                resultSet: list,
            }

        case CHANGE_ACTIVE_VEHICLE:

            const alterState = state.resultSet.map(vehicle => {

                if (vehicle.id === action.payload.id) {

                    return {
                        ...vehicle,
                        active: action.payload.active
                    }
                }

                return vehicle
            })

            return {
                ...state,
                resultSet: alterState,
            }

        case UPDATE_VEHICLE:

            const alterData = state.resultSet.map(vehicle => {

                if (vehicle.id === action.payload.id) {

                    return {
                        ...vehicle,
                        name:               action.payload.name,
                        id_vehicle_type:    action.payload.id_vehicle_type
                    }
                }
                
                return vehicle
            })

            return {

                ...state,
                resultSet: alterData,
            }

        case DELETE_VEHICLE:

            state.resultSet.map(vehicle => {

                if (vehicle.id === action.payload.id) {

                    state.resultSet.delete(vehicle)
                    
                    return 
                }

            })

        case RESET_VEHICLE:

            return {
                ...INITIAL_STATE,
            }

        default:
            return state;
    }
}
import { useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export default function FormikTextInputPassword(props) {
    const {
        label,
        name,
        value,
        onChange,
        error,
        className,
    } = props;
    const [showPassword, setShowPassword] = useState(false);

    return (
        <FormControl className={className} variant="outlined" required error={error}>
            <InputLabel htmlFor="outlined-adornment-password">
                {label}
            </InputLabel>

            <OutlinedInput
                id={`outlined-adornment-${label.trim()}`}
                name={name}
                type={showPassword ? 'text' : 'password'}
                value={value}
                onChange={(value) => { onChange(value) }}
                error={error}
                required
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
                label={label}
                style={{ height: 40 }}
            />
        </FormControl>
    )
}
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Settings from "@material-ui/icons/Settings";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from '@material-ui/core/Menu';
import { useColors } from '../../App/hooks/useColors';
import { vehicleTypes, getIcon } from '../../App/utils/typesVehicles';
import useStyles from "../../App/hooks/useStyles";
import './styles.css';

export default function VehicleTable({ resultSetActives, resultSet, onChangeVehicleActive, onChangeVehicle, onDeleteVehicle }) {

    const colors = useColors();
    const classes = useStyles();

    function handleChangeVehicleData(vehicle, close = () => { }) {

        if (typeof onChangeVehicle === 'function')
            onChangeVehicle(vehicle);

        close()
    }

    function handleChangeActive(vehicle, close = () => { }) {

        if (typeof onChangeVehicleActive === 'function')
            onChangeVehicleActive(vehicle);

        close()
    }

    function handleDeleteVehicle(vehicle, close = () => { }) {

        if (typeof onDeleteVehicle === 'function')
            onDeleteVehicle(vehicle);

        close()
    }

    function handleViewData (vehicle) {

        // console.log(vehicle)
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className="no-mobile-column">Categoria</TableCell>
                        <TableCell align="center" className="no-mobile-column"></TableCell>
                        <TableCell align="left">Descrição</TableCell>
                        <TableCell align="left">Placa</TableCell>
                        <TableCell align="right">Opções</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resultSet.map((vehicle) => (

                        <TableRow key={vehicle.id} className={classes.tableRow} onClick={ () => handleViewData(vehicle)}>

                            <TableCell align="center" className="no-mobile-column">{getIcon(vehicle.id_vehicle_type, vehicle.active ? colors.greenCS : colors.red)}</TableCell>
                            <TableCell align="left" size="small" className="no-mobile-column">{vehicleTypes[vehicle.id_vehicle_type - 1].name}</TableCell>
                            <TableCell align="left" className={classes.tableCell}>{vehicle.name}</TableCell>
                            <TableCell align="left" className={classes.tableCell}>{vehicle.plaque}</TableCell>
                            <TableCell align="right" className={classes.tableCell}>
                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState) => (
                                        <>
                                            <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                                <Settings />
                                            </IconButton>

                                            <Menu {...bindMenu(popupState)}>

                                                <MenuItem onClick={() => handleChangeVehicleData(vehicle, popupState.close())}>
                                                    Alterar os dados
                                                </MenuItem>

                                                <MenuItem onClick={() => handleChangeActive(vehicle, popupState.close())}>
                                                    {resultSetActives ? 'Inativar ' : 'Ativar '} veículo
                                                </MenuItem>

                                                <MenuItem onClick={() => handleDeleteVehicle(vehicle, popupState.close())}>
                                                    Excluir
                                                </MenuItem>

                                            </Menu>
                                        </>
                                    )}
                                </PopupState>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
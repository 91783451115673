import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import TextInput from '../App/components/TextInput';
import Loader from '../App/components/Loader';
import MainWithData from '../App/components/MainWithData';
import MainWithoutData from '../App/components/MainWithoutData';
import AlertModalDialog from '../App/components/AlertModalDialog';
import MUIButton from '../App/components/MUIButton';
import ParkingTable from './components/ParkingTable';
import { setMaps } from './parkingActions';
import './styles.css';

export default function Parking() {
    const { parkings } = useSelector((state) => state.parking);
    const { user } = useSelector((state) => state.user);
    const history = useHistory();
    const dispatch = useDispatch();
    const [city, setCity] = useState('');
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    useEffect(() => {
        if (!user.hasOwnProperty('id')) {
            window.localStorage.clear();
            history.push('/');
        }
    }, [user, history])

    useEffect(() => {
        setLoading(false);

        // navigator.geolocation.getCurrentPosition(function(position) {
        //     console.log(position.coords.latitude, position.coords.longitude)
        //   });
    }, [])

    function getTableData() {
        if (parkings.length === 0)
            return (
                <MainWithoutData
                    title="Lista dos estabelecimentos"
                    text="Pesquise uma cidade ou permite o acesso da sua localização para listar os estabelecimentos."
                />
            )

        return (
            <MainWithData title="Lista dos estabelecimentos">
                <ParkingTable resultSet={parkings} />
            </MainWithData>
        )
    }

    function handleFilterParking() {
        setLoading(true);
        if (city.trim() === '') {
            setLoading(false);
            return setModal({
                open: true,
                title: 'Aviso!',
                text: 'Insira o nome da cidade corretamente.'
            })
        }

        dispatch(setMaps(city, (resp) => {
            if (!resp) {
                setModal({
                    open: true,
                    title: 'Aviso!',
                    text: `Não achamos nenhum estabelecimentos em ${city}.`
                })
            }
        }))

        setLoading(false);
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card id="header-container">
                    <div className="title-content">
                        <Typography variant="h4">Estabelecimentos</Typography>
                        <Typography variant="h6">Consultas dos estabelecimentos cadastrados</Typography>
                    </div>
                    <div className="filter-content">
                        <TextInput
                            label="Cidade"
                            value={city}
                            onChange={(e) => { setCity(e.target.value) }}
                            className="filter-itens"
                        />
                        <MUIButton
                            color="primary"
                            className="filter-itens"
                            onClick={handleFilterParking}>
                            Filtrar
                        </MUIButton>
                    </div>
                </Card>
            </Grid>

            <Grid item xs={12}>
                {!loading ? getTableData() : (<Loader loading={loading} />)}
            </Grid>

            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
        </Grid>
    );
}
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import MUIButton from '../../App/components/MUIButton';
import { TicketStatus } from './TicketStatus'
import { ENUM_TICKET_STATUS } from '../TicketStatusEnum'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from '@material-ui/core/Menu';
import Settings from "@material-ui/icons/Settings";
import GreatMask from '../../App/utils/GreatMask'
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import useStyles from "../../App/hooks/useStyles";
import { Dialog, DialogContent, Divider } from '@material-ui/core';
import FormikTextInput from '../../App/components/FormikTextInput';
import ReceiptIcon from "@material-ui/icons/Receipt";
import Toast from '../../App/utils/Toast';
import { useDispatch, useSelector } from 'react-redux';
import { list, deletePaymentCard } from '../../PaymentCard/paymentCardActions.js';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import { useFormik } from 'formik';
import Validation from '../../App/utils/Validation';
import { useHistory } from 'react-router';
import AddressNotify from './AddressNotify'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { payTicket, getPixQrCode } from '../ticketActions';
import Loader from '../../App/components/Loader';
import PixImage from '../../App/assets/pix.png';
import AsaasLogo from '../../App/assets/asaas.png';
import '../styles.css';

export default function TicketTable({ resultSet, updateTicket, payTicket }) {
    
    const { paymentCardList, affectedRows, where } = useSelector((state) => state.paymentCard);
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    var now = useState(new Date());
    const [modal, setModal] = useState({
            open: false,
            title: '',
            text: '',
        });

    const [modalPayTicket, setModalPayTicket] = useState({
            open: false,
            title: '',
            text: '',
        });

    const [modalPixQrCode, setModalPixQrCode] = useState({
            open: false,
            title: '',
            text: '',
        });

    const [paymentForm, setPaymentForm] = useState('')
    const [newPaymentCard, setNewPaymentCard] = useState(true)

    now = now[0]

    // console.log(now)

    const [selectedTicket, setSelectedTicket] = useState();
    const [selectedQrCode, setSelectedQrCode] = useState();

    function handleUpdateTicket () {

        updateTicket(selectedTicket)
    }    

    function handlePayTicket(ticket) {

        if (ticket.invoice_url) {

            var win = window.open(ticket.invoice_url, '_blank');
            win.focus();
        }
        else 
            payTicket(ticket)
    }

    function handleViewVoucher(id_ticket) {

        // console.log(id_ticket)

        var CryptoJS = require("crypto-js");

        let encrypted = CryptoJS.AES.encrypt(id_ticket.toString(), "secrete_key").toString()
        var encoded = CryptoJS.enc.Base64.parse(encrypted).toString(CryptoJS.enc.Hex);

        // console.log(encrypted)
        // console.log(encoded)

        // var decoded = CryptoJS.enc.Hex.parse(encoded).toString(CryptoJS.enc.Base64);
        // var decrypted = CryptoJS.AES.decrypt(encrypted, "secrete_key").toString(CryptoJS.enc.Utf8);

        // console.log(decrypted)

        var win = window.open(`https://voucher.estacionefacil.consforsistemas.com.br/event-voucher/ticket-voucher/${encoded}`, '_blank');
        win.focus();
    }

    function handleShowData (ticket) {

        setSelectedTicket(ticket)

        setModal({ ...modal, open: true })
    }

    function handleShowModalPayTicket (ticket) {

        Toast.loading('Aguarde...');

        setLoading(true);

        dispatch(list( { offset: where.offset, limit: where.limit }, (resp) => {

            setLoading(false);

            if (typeof resp === 'string') {

                Toast.showToast(resp, '')
            }

            if (typeof resp === 'boolean' && !resp) {

                Toast.showToast(resp, '')
            }

            setSelectedTicket(ticket)

            setModalPayTicket({ ...modalPayTicket, open: true })

            Toast.dismiss();
        }));
    }

    function handleShowPixQrCode (ticket) {

        Toast.loading('Aguarde...');

        setLoading(true);

        dispatch(getPixQrCode( ticket.payment_id, (resp) => {

            setLoading(false);

            // console.log(resp.resultSet)

            if (typeof resp === 'string') {

                Toast.showToast(resp, '')

                return
            }

            if (typeof resp === 'boolean' && !resp) {

                Toast.showToast('Não foi possível busca o QrCode', '')

                return
            }

            setSelectedTicket(ticket)
            setSelectedQrCode(resp.resultSet)

            setModalPixQrCode({ ...modalPixQrCode, open: true })

            Toast.dismiss();
        }));
    }

    function cancel() {

        setModal({ ...modal, open: false })
        setModalPayTicket({ ...modal, open: false })
        setModalPixQrCode({ ...modalPixQrCode, open: false })
    }


    return (

        <TableContainer component={Paper}>

            <Table className={classes.table} aria-label="simple table">
            
                <TableHead>
                    <TableRow >
                        <TableCell>Evento</TableCell>
                        <TableCell className="no-mobile-column">Local</TableCell>
                        <TableCell className="no-mobile-column">Data</TableCell>
                        <TableCell className="no-mobile-column">Placa</TableCell>
                        <TableCell className="no-mobile-column">Veículo</TableCell>
                        <TableCell className="no-mobile-column">Ticket</TableCell>
                        <TableCell className="no-mobile-column">Expira</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell >Valor</TableCell>
                        <TableCell></TableCell>
                        <TableCell className="no-mobile-column"></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>

                    {resultSet.map((ticket) => (

                        <TableRow key={ticket.id} className={classes.tableRow}>
                            
                            {/* console.log(ticket) */}
                            
                            <TableCell component="th" scope="row" onClick={() => handleShowData(ticket)}>{ticket.eventDateValue?.eventDate?.event?.name} - {ticket.eventDateValue?.eventDate?.description}</TableCell>
                            <TableCell component="th" scope="row" className="no-mobile-column">{ticket.eventDateValue?.eventDate?.event.local}</TableCell>
                            <TableCell component="th" scope="row" className="no-mobile-column">{ticket.eventDateValue?.eventDate?.date}</TableCell>
                            <TableCell component="th" scope="row" className="no-mobile-column">{ticket.ticketVehicle?.plaque}</TableCell>
                            <TableCell component="th" scope="row" className="no-mobile-column">{ticket.ticketVehicle?.name}</TableCell>
                            <TableCell component="th" scope="row" className="no-mobile-column">{ticket.ticket}</TableCell>
                            <TableCell component="th" scope="row" className="no-mobile-column">{ format(new Date(ticket.expire_at), 'Pp', { locale: ptBR })}</TableCell>

                            <TableCell align="center" padding="none">
                                {(now > new Date(ticket.expire_at) || ticket.eventTicketStatus[0]?.status === ENUM_TICKET_STATUS.EXPIRADO) && !ticket.paid
                                    ?
                                        <TicketStatus status={'EXPIRADO'} msg={'EXPIRADO'} />
                                    :
                                        <TicketStatus status={ticket.eventTicketStatus[0]?.status} msg={ticket.eventTicketStatus[0]?.msg} />
                                }
                            </TableCell> 

                            <TableCell component="th" scope="row" >R$ {GreatMask.coinBR(ticket.eventDateValue?.value_online)}</TableCell>

                            <TableCell align="center" padding="none">
                                
                                {now < new Date(ticket.expire_at) && !ticket.paid && !ticket.payment_id && ticket.eventTicketStatus[0]?.status  ===  ENUM_TICKET_STATUS.AGUARDANDO_PAGAMENTO || ticket.eventTicketStatus[0]?.status  ===  ENUM_TICKET_STATUS.COBRANCA_GERADA
                                    ? 
                                         <MUIButton
                                            color="primary"
                                            className="filter-itens"
                                            buttonType="green"
                                            onClick={() => handleShowModalPayTicket(ticket)}>

                                            Pagar

                                        </MUIButton>
                                    :
                                        ''
                                }

                                {now < new Date(ticket.expire_at) && !ticket.paid && ticket.eventTicketStatus[0]?.status  ===  ENUM_TICKET_STATUS.AGUARDANDO_PAGAMENTO && ticket.billing_type  ===  'PIX' && ticket.payment_id
                                    ? 
                                         <MUIButton
                                            color="primary"
                                            className="filter-itens"
                                            buttonType="green"
                                            onClick={() => handleShowPixQrCode(ticket)}>

                                            QrCode

                                        </MUIButton>
                                    :
                                        ''
                                }

                                {ticket.paid
                                    ? 
                                         <MUIButton
                                            color="primary"
                                            className="filter-itens"
                                            buttonType="green"
                                            onClick={() => handlePayTicket(ticket)}>

                                            Fatura

                                        </MUIButton>
                                    :
                                        ''
                                }

                                {ticket.paid
                                    ? 
                                         <MUIButton
                                            color="primary"
                                            className="filter-itens"
                                            buttonType="blue"
                                            onClick={() => handleViewVoucher(ticket.id)}>

                                            Voucher

                                        </MUIButton>


                                    :
                                        ''
                                }
                            </TableCell>

                            <TableCell align="right" className="no-mobile-column">

                                {now < new Date(ticket.expire_at) && !ticket.paid && ticket.eventTicketStatus[0]?.status  ===  ENUM_TICKET_STATUS.AGUARDANDO_PAGAMENTO || ticket.eventTicketStatus[0]?.status  ===  ENUM_TICKET_STATUS.COBRANCA_GERADA
                                    ?
                                        <PopupState variant="popover" popupId="demo-popup-menu">
                                            {(popupState) => (
                                                <>
                                                    <IconButton aria-label="Abrir opções" {...bindTrigger(popupState)}>
                                                        <Settings />
                                                    </IconButton>

                                                    <Menu {...bindMenu(popupState)}>

                                                        <MenuItem onClick={() => handleUpdateTicket(popupState.close())}>
                                                            Alterar
                                                        </MenuItem>
                                                    </Menu>
                                                </>
                                            )}
                                        </PopupState>
                                    :

                                        ''
                                }
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <AlertModalDialog
                open={modal}
                ticket={selectedTicket}
                cancel={cancel}
                handleUpdateTicket={handleUpdateTicket}
            />

            <AlertModalDialogPayTicket
                open={modalPayTicket}
                ticket={selectedTicket}
                paymentCardList={paymentCardList}
                paymentForm={paymentForm}
                setPaymentForm={setPaymentForm}
                handleShowPixQrCode={handleShowPixQrCode}
                cancel={cancel}
            />

            <AlertModalDialogPixQrCode
                open={modalPixQrCode}
                ticket={selectedTicket}
                qrcode={selectedQrCode}
                cancel={cancel}
            />
        </TableContainer>
    );
}

function handleSetPaymentForm(event, setPaymentForm, formik) {

    // console.log(event.target.value)

    setPaymentForm(event.target.value)

    formik.values.paymentForm = event.target.value

    if (event.target.value === 'newCard')
        formik.values.newPaymentCard = true
    else
        formik.values.newPaymentCard = false
}

function AlertModalDialogPayTicket({ open, ticket, paymentCardList, paymentForm, setPaymentForm, handleShowPixQrCode, cancel }) {
    
    const { user, address } = useSelector((state) => state.user);
    const history = useHistory();
    const [openDialogAddressNotify, setOpenDialogAddressNotify] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({

        initialValues: {

            number:         '',
            holder_name:    '',
            expiry_at:      '',
            ccv:            '',
            paymentForm:    '',
            newPaymentCard: false,
            savePyamentCard: false,
        },
        validate: values => {

            const errors = {};

            if (values.number.length !== 19) {
                errors.number = true;
            }

            if (values.expiry_at.length !== 5) {
                errors.expiry_at = true;
            }

            if (!values.holder_name) {
                errors.holder_name = true;
            }

            if (values.ccv.length !== 3) {
                errors.ccv = true;
            }

            return errors;
        },
       
    });

    function confirmPayment() {

        // console.log(address[0].number)

        if (address.length <= 0) {

            setOpenDialogAddressNotify(true)

            return
        }
        
        let billing_type = ''
        let credit_card_id = null
        let expiry_at_month = ''
        let expiry_at_year = ''

        if (formik.values.paymentForm === 'pix')
            billing_type = 'PIX'

        if (formik.values.paymentForm === 'newCard') {

            billing_type = 'CREDIT_CARD'

            expiry_at_month = formik.values.expiry_at.split('/')[0]
            expiry_at_year = formik.values.expiry_at.split('/')[1]
        }

        if (!isNaN(formik.values.paymentForm)) {

            billing_type = 'CREDIT_CARD'
            credit_card_id = formik.values.paymentForm
        }

        Toast.loading('Aguarde...');

        setLoading(true);

        dispatch(payTicket({ 

            ticket_id :                 String(ticket.id),
            user_name:                  user.name,
            user_cpf:                   user.cpf,
            user_phone:                 user.phone_number, 
            user_email:                 user.email,
            billing_type:               billing_type,
            address_zip_code:           address[0].address.zip_code,
            address_number:             address[0].number,
            credit_card_holder_name:    formik.values.holder_name.toUpperCase(),
            credit_card_number:         formik.values.number.replaceAll(' ', ''),
            credit_card_expiry_month:   expiry_at_month,
            credit_card_expiry_year:    expiry_at_year,
            credit_card_ccv:            formik.values.ccv,
            credit_card_id:             credit_card_id,
            save_credit_card:           formik.values.savePyamentCard,
        }, 
        (resp) => {

            setLoading(false);

            if (typeof resp === 'string') {

                Toast.showToast(resp, '')
            }

            if (typeof resp === 'object') {

                // console.log(resp)

                if (resp.paid) {

                    Toast.showToast(true, 'Pagamento efetuado com sucesso!')
                }
                else if (resp.billing_type === 'PIX' && resp.payment_id !== '')  {

                    Toast.showToast(true, 'Cobrança gerada com sucesso!')

                    handleShowPixQrCode(ticket)
                }

                cancel()
            }

            if (typeof resp === 'boolean' && !resp) {

                Toast.showToast('Não foi possível efetuar o pagamento.\n Verifique os dados e tente novamente.', '')
            }

            // Toast.dismiss();
        }));
    }

    function handleConfirmNewAddress() {

        history.push('/myaccount')
    }

    function handleChangeSwitch(value) {

        console.log(value)

        formik.values.savePyamentCard = value
    }

    // console.log(formik.values.paymentForm)

    function getDialog() {

        return (
            <Dialog
                open={open.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                item xs={12}
                fullWidth={true}
            >   

                {openDialogAddressNotify && (
                    <AddressNotify open={openDialogAddressNotify} onConfirm={handleConfirmNewAddress}/>
                )}

                <Typography variant="h5" className="dialog-title-left"> Selecione a forma de pagamento</Typography>

                <DialogContent style={{padding: 5, margin: 10, border: "0px solid black"}} >

                    <ToggleButtonGroup
                      value={formik.values.paymentForm}
                      exclusive
                      aria-label="text alignment"
                      orientation='vertical'
                      style={{width: '100%', spacing: 5}}
                      onChange={(e) => handleSetPaymentForm(e, setPaymentForm, formik)}
                    >

                        {paymentCardList[0]?.map((item) => (

                            <ToggleButton value={item.id.toString()} key={item.id}>
                                **** **** **** {item.number} {item.brand}
                            </ToggleButton>
                        ))}

                        {paymentCardList.length > 0 
                            ?

                                <ToggleButton value="newCard" >
                                    Novo Cartão
                                </ToggleButton>
                            :

                                <ToggleButton value="newCard" >
                                    Cartão de Credito
                                </ToggleButton>
                        }

                        {formik.values.newPaymentCard 
                            ?

                                <div className="full-input">

                                     <FormControlLabel
                                        control={
                                            <Switch
                                                value={formik.values.savePaymentCard}
                                                onChange={(e) => formik.setFieldValue('savePyamentCard', e.target.checked)}
                                                color="primary"
                                                name="savePyamentCard"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                            />
                                        }

                                        label="Gravar Cartão"
                                    />

                                    <FormikTextInput
                                        label="Número do Cartão"
                                        name="number"
                                        className="filter-itens"
                                        InputLabelProps={{ maxLength: 19 }}
                                        required
                                        onChange={(e) => formik.setFieldValue('number', GreatMask.cartaoCredito(e.target.value))}
                                        value={formik.values.number}
                                        error={formik.errors.number}
                                    />

                                    <FormikTextInput
                                        label="Validade (MM/YY)"
                                        className="filter-itens"
                                        required
                                        onChange={(e) => formik.setFieldValue('expiry_at', GreatMask.monthYear(e.target.value))}
                                        InputLabelProps={{ maxLength: 5 }}
                                        value={formik.values.expiry_at}
                                        error={formik.errors.expiry_at}
                                        name="expiry_at"
                                        />


                                    <FormikTextInput
                                        label="Nome e sobrenome no cartão"
                                        className="filter-itens"
                                        required
                                        onChange={formik.handleChange}
                                        value={formik.values.holder_name.toUpperCase()}
                                        error={formik.errors.holder_name}
                                        name="holder_name"
                                        />

                                    <FormikTextInput
                                        label="Código de segurança"
                                        className="filter-itens"
                                        required
                                        onChange={(e) => formik.setFieldValue('ccv', GreatMask.onlyNumber(e.target.value))}
                                        value={formik.values.ccv}
                                        error={formik.errors.ccv}
                                        name="ccv"
                                        InputLabelProps={{ maxLength: 3 }}
                                        />
                                </div> 
                            : ''
                        }

                        <ToggleButton value="pix" >
                            PIX
                        </ToggleButton>
                    </ToggleButtonGroup>

                </DialogContent>

                <div className="button-content">
                    <MUIButton onClick={cancel} buttonType="red">
                        Voltar
                    </MUIButton>

                    <MUIButton onClick={confirmPayment} buttonType="green" disabled={!formik.values.paymentForm || (formik.values.newPaymentCard && !formik.isValid)}>
                        Confirmar
                    </MUIButton>
                </div>

                    <Divider />

                <div className="div-payment-info" style={{border: "0px solid"}}>

                    <img src={AsaasLogo} alt="" style={{width: "25%"}}/>

                    O pagamento será concluído de forma totalmente segura  com a plataforma Asaas.

                    <br/> <a href="https://www.asaas.com/" target="_blank">Para mais informações clique aqui </a>
                </div>
            </Dialog>
        );
    }

    return (

            <Dialog
                open={open.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                item xs={12}
                fullWidth={true}
            >
            
                { !loading ? getDialog() : (<Loader loading={loading} />) }

            </Dialog>
    );
}

function AlertModalDialogPixQrCode({ open, ticket, qrcode, cancel }) {
    
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    function copiarAreaTransferencia () {

        navigator.clipboard.writeText(qrcode?.payload);

        Toast.success('Copiado para a area de transferência')
    }

    function getDialog() {

        return (
            <Dialog
                open={open.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth={true}
            >   
                
                <DialogContent className="dialog-content" style={{padding: 0, margin: 0, border: "0px solid black",  }} >

                    <div className="pix-dialog">

                        <Card className="card-content">
                            <div className="pix-dialog"> 
                                <img src={PixImage} alt="" style={{width: "30%"}}/>

                                Leia o QRCode pelo aplicativo do ceular
                             </div>

                            <div className="pix-dialog">  
                                <img src={`data:image/png;base64, ${qrcode?.encodedImage}`} style={{width: "60%"}} />

                                Para usar o "Pix Copia e Cola" copie o código e cole para completar a transacão.
                            </div>
                            
                            <div className="div-row">  </div>
                        </Card>

                        <Card className="card-content">
                            <div className="div-row"> 
                                <div className="pix-dialog" style={{"align-items": "start" }}> 
                                    Valor Transação:
                                </div>

                                <div className="pix-dialog" style={{"align-items": "end" }}> 
                                    R$ {GreatMask.coinBR(ticket?.eventDateValue?.value_online)}
                                </div>
                            </div>
                        </Card>

                        <Card className="card-content">
                             <div className="pix-dialog"> 
                                <MUIButton onClick={copiarAreaTransferencia} buttonType="blue">
                                    Copia e Cola Pix
                                </MUIButton>
                            </div>
                        </Card>
                    </div>
                </DialogContent>

                <div className="button-content">
                    <MUIButton onClick={cancel} buttonType="red">
                        Voltar
                    </MUIButton>
                </div>
            </Dialog>
        );
    }

    return (

            <Dialog
                open={open.open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                item xs={12}
                fullWidth={true}
            >
            
                { !loading ? getDialog() : (<Loader loading={loading} />) }

            </Dialog>
    );
}

function AlertModalDialog({ open, ticket, cancel, handleUpdateTicket }) {
    
    // console.log(ticket)

    return (
        <Dialog
            open={open.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            item xs={12}
            className="dialog"
        >

            <Typography variant="h5" className="dialog-title-left"> Dados do Ticket</Typography>

            <DialogContent 
                style={{padding: 5, margin: 10, border: "0px solid black"}} 
            >   

                <div className="half-div">

                    <FormikTextInput
                        label="Evento"
                        value={ticket?.eventDateValue?.eventDate?.event.name}
                        disabled="true"
                        className="filter-itens"
                    />


                    <FormikTextInput
                        label="Descrição"
                        value={ticket?.eventDateValue?.eventDate?.description}
                        disabled="true"
                        className="filter-itens"
                        />

                    <FormikTextInput
                        label="Local"
                        value={ticket?.eventDateValue?.eventDate?.event.local}
                        disabled="true"
                        className="filter-itens"
                        />


                    <FormikTextInput
                        label="Data"
                        value={ticket?.eventDateValue?.eventDate?.date}
                        disabled="true"
                        className="filter-itens"
                        />
                </div>

                <div className="half-div" >

                    <FormikTextInput
                        label="Placa"
                        value={ticket?.ticketVehicle?.plaque}
                        disabled="true"
                        className="filter-itens"
                    />

                    <FormikTextInput
                        label="Veículo"
                        value={ticket?.ticketVehicle?.name}
                        disabled="true"
                        className="filter-itens"
                        />

                    <FormikTextInput
                        label="Tipo Veículo"
                        value={ticket?.ticketVehicle?.vehicle_type.name}
                        disabled="true"
                        className="filter-itens"
                        />
                </div>

                <div className="half-div">

                    <FormikTextInput
                        label="Ticket"
                        value={ticket?.ticket}
                        disabled="true"
                        className="filter-itens"
                        />

                    <FormikTextInput
                        label="Valor"
                        value={GreatMask.coinBR(ticket?.eventDateValue?.value_online)}
                        disabled="true"
                        className="filter-itens"
                        />
                </div>

                <div className="half-div">
                </div>

                <div className="button-content" >
                    <MUIButton onClick={handleUpdateTicket} buttonType="blue">
                        Alterar Veículo
                </MUIButton>
                </div>
            </DialogContent>

            <Divider/>
            
           <div className="button-content">
                <MUIButton onClick={cancel} buttonType="red">
                    Voltar
                </MUIButton>
            </div>
        </Dialog>
    );
}



import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { history } from './App/services/HistoryService';
import AuthenticatedRouter from "./App/AuthenticatedRouter";
import { store, persistor } from './App/store';
import { refreshInterceptor } from './App/services/RefreshService';
import Loader from './App/components/Loader';
import Login from "./Login";
import Dashboard from "./Dashboard";
import History from "./History";
import MyAccount from "./MyAccount";
import Parking from "./Parking";
import Vehicle from "./Vehicle";
import Event from "./Event";
import Ticket from "./Ticket";
import PaymentCard from "./PaymentCard";
import DeleteAccountDetails from "./DeleteAccountDetails";
import Policies from "./Policies";
import './styles.css';

refreshInterceptor();

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={<Loader loading={true} />} persistor={persistor}>
            <Router history={history}>
                <BrowserRouter>
                    <Switch>
                        <Route path="/login/:event_date_id?" exact component={Login} />
                        <AuthenticatedRouter path="/dashboard" component={Dashboard} />
                        <AuthenticatedRouter path="/history" component={History} />
                        <AuthenticatedRouter path="/myaccount" component={MyAccount} />
                        <AuthenticatedRouter path="/parking" component={Parking} />
                        <AuthenticatedRouter path="/vehicle" component={Vehicle} />
                        <AuthenticatedRouter path="/event/:event_date_id?" component={Event} />
                        <AuthenticatedRouter path="/ticket" component={Ticket} />
                        <AuthenticatedRouter path="/payment-card" component={PaymentCard} />
                        <Route path="/excluirconta" exact component={DeleteAccountDetails} />
                        <Route path="/politicaprivacidade" exact component={Policies} />
                        <Route path="*" component={() => <h1>Page not found</h1>} />
                    </Switch>
                </BrowserRouter>
            </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent, Dialog, Divider } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { useColors } from '../../App/hooks/useColors';
import { vehicleTypes, getIcon } from '../../App/utils/typesVehicles';
import GreatMask from '../../App/utils/GreatMask';
import Validation from '../../App/utils/Validation';
import FormikSelect from '../../App/components/FormikSelect';
import FormikTextInput from '../../App/components/FormikTextInput';
import MUIButton from '../../App/components/MUIButton';
import AlertModalDialog from '../../App/components/AlertModalDialog';
import Loader from '../../App/components/Loader';
import { update, insert, vehicleExist } from '../VehicleActions';
import './styles.css';
import Toast from '../../App/utils/Toast';

export default function FormNewVehicleDialog({ open, setOpen, vehicle }) {

    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const colors = useColors();
    const [loading, setLoading] = useState(false);
    const [editVehicle, setEditVehicle] = useState(false);
    const [vehicleExistDataBase, setVehicleExist] = useState(false); // veiculo ja existe no banco, vai ser apenas vinculado ao usuario

    const [modal, setModal] = useState({
        open: false,
        title: '',
        text: '',
    });

    const formik = useFormik({

        initialValues: {

            id:                 vehicle?.id || 0,
            active:             vehicle?.active || true,
            id_vehicle_type:    vehicle?.id_vehicle_type || '1',
            name_type_vehicle:  vehicle?.vehicle_type?.name || 'Carro',
            plaque:             vehicle?.plaque || '',
            renavam:            vehicle?.renavam || '',
            name:               vehicle?.name || '',
            photo: '',
        },
        validate: values => {

            const errors = {};

            if (values.plaque.length !== 7 || !Validation.plaque(values.plaque)) {
                errors.plaque = true;
            }

            /*if (values.renavam.length !== 11) {
                errors.renavam = true;
            }*/

            if (!values.name) {
                errors.name = true;
            }

            /*if (!values.photo && !editVehicle) {
                errors.photo = true;
            }*/

            return errors;
        },
        onSubmit: values => {

            setLoading(true);

            (editVehicle || vehicleExistDataBase) ? handleChange(values) : handleCreate(values) 
        },
    });

    useEffect(() => {

        if (vehicle?.id) {

            setEditVehicle(true);
        }

    }, [vehicle])

    function handleCreate(values) {

        Toast.loading('Aguarde...');

        dispatch(insert(values, (resp) => {

            /*setModal({

                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
            })*/


            Toast.showToast(resp, 'Cadastrado com sucesso!')

            if (typeof resp === 'boolean' && resp) {

                handleClose();
            }
            else
                Toast.showToast('Não foi possível cadastrar.', '')

            setLoading(false);
        }));
    }

    function handleChange(values) {

        Toast.loading('Aguarde...');

        dispatch(update({

            id:              String(values.id),
            name:            values.name,
            id_vehicle_type: Number(values.id_vehicle_type),
            id_user_owner:   values.id_user_owner

        }, (resp) => {

            /*setModal({

                open: true,
                title: typeof resp === 'boolean' && resp ? 'Sucesso' : 'Aviso',
                text: typeof resp === 'boolean' && resp ? 'Cadastro com sucesso!' : resp
            })*/

            if (typeof resp === 'boolean' && resp) {

                Toast.showToast(resp, 'Cadastro atualizado com sucesso!')

                handleClose();
            }
            else
                Toast.showToast('Nao foi possivel atualizar o cadastro!', '')

            setLoading(false);
        }));
    }

    function handleClose() {

        formik.resetForm();

        if (typeof setOpen === 'function')
            setOpen();
    }

    function verifyVehicleExist(plaque) {

        setLoading(true)

        Toast.loading('Aguarde...');

        dispatch(vehicleExist(GreatMask.toUpperCaseOnlyText(plaque), resp => {

            formik.setFieldValue('name', '')
            formik.setFieldValue('renavam', '')

            if (typeof resp === 'boolean' && resp) {


                Toast.dismiss()

                setLoading(false)

                return
            }

            if (typeof resp === 'object') {

                Toast.dismiss()

                setLoading(false)

                if (resp[0].id_user_owner) {

                    Toast.showToast('Veículo já cadastro.', '')

                    if (user.id !== resp[0].id_user_owner) {

                        setModal({
                    
                            open: true,
                            title: "Aviso",
                            text: 'Veículo já cadastro para outro usuário.'
                        })
                    }

                    if (user.id === resp[0].id_user_owner) {

                        setModal({
                    
                            open: true,
                            title: "Aviso",
                            text: 'Veículo já está na sua lista.'
                        })
                    }
                }
                else {

                    setVehicleExist(true)

                    formik.setFieldValue('id',              resp[0].id);
                    formik.setFieldValue('name',            resp[0].name);
                    formik.setFieldValue('renavam',         resp[0].renavam);
                    formik.setFieldValue('id_vehicle_type', resp[0].id_vehicle_type);
                    formik.setFieldValue('id_user_owner',   user.id);
                }
                
                return
            }

            Toast.showToast(resp, '')

            setLoading(false)

            setModal({
                
                open: true,
                title: "Aviso",
                text: resp
            })
        }))
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth="sm"
        >
            <Typography variant="h5" className="dialog-title-left">{!editVehicle ? 'Cadastrar novo Veículo' : 'Alterar o Veículo'}</Typography>
            
            <DialogContent style={{ padding: 5 }}>

                <div className="content-form">
                    
                    <form
                        onSubmit={formik.handleSubmit}
                        className="form"
                    >
                        <div className="half-div">

                            <FormikSelect
                                label="Tipo do veículo"
                                name="id_vehicle_type"
                                value={formik.values.id_vehicle_type}
                                onChange={(e) => {
                                    formik.setFieldValue('id_vehicle_type', vehicleTypes[e.target.value - 1].id_vehicle_type);
                                    formik.setFieldValue('name_type_vehicle', vehicleTypes[e.target.value - 1].name);
                                }}
                                className="half-input"
                                required
                                disabled={vehicleExistDataBase ? true : false}
                            >
                                {vehicleTypes.map((item) => <MenuItem value={item.id_vehicle_type} key={item.id_vehicle_type} className="menu">
                                    <div className="menu-itens">
                                        {`${item.name}`}
                                    </div>
                                </MenuItem>
                                )}

                            </FormikSelect>

                            <FormikTextInput
                                label="Placa"
                                name="plaque"
                                value={formik.values.plaque.toUpperCase()}
                                
                                onChange={(e) => { 
                                    let plaque = e.target.value 
                                    formik.setFieldValue('plaque', plaque) 
                                    plaque.length === 7 && verifyVehicleExist(plaque)
                                }}

                                disabled={editVehicle ? true : false}
                                InputLabelProps={{ maxLength: 7 }}
                                className="half-input"
                                required
                                error={formik.errors.plaque}
                            />
                        </div>

                        <div className="half-div">

                            <FormikTextInput

                                label="Descrição do veículo"
                                name="name"
                                value={formik.values.name.toUpperCase()}
                                onChange={formik.handleChange}
                                className="full-input"
                                required
                                disabled={vehicleExistDataBase ? true : false}
                                error={formik.errors.name}
                            />
                        </div>

                        {/*<div className="input-photo">
                            <InputLabel htmlFor="my-input" className="input-label">Imagem do documento *</InputLabel>
                            <Input
                                type="file"
                                name="photo"
                                className="full-input"
                                accept="image/png, image/jpeg"
                                multiple
                                value={formik.values.photo}
                                onChange={formik.handleChange}
                                required
                                disabled={editVehicle ? true : false}
                                error={formik.errors.photo}
                            />
                        </div> */}

                        <Divider />

                        <div className="button-content">

                            <MUIButton onClick={handleClose} buttonType="red">
                                Cancelar
                            </MUIButton>

                            <MUIButton
                                type="submit"
                                buttonType="green"
                                disabled={formik.isValid === true && formik.dirty === true || vehicleExistDataBase ? false : true}
                            >
                                {!editVehicle ? 'Cadastrar' : 'Alterar'}
                            </MUIButton>
                        </div>
                    </form>
                </div>
            </DialogContent>
            <AlertModalDialog
                open={modal}
                setOpen={() => setModal({ ...modal, open: false })}
            />
            {loading && <Loader loading={loading} />}
        </Dialog>
    );
}